import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorageService } from '../storage/localstorage.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { reference } from '@popperjs/core';



@Injectable({
  providedIn: 'root'
})

export class ParameterService {


  constructor(private http: HttpClient) {

  }

  //programme
  fetchDataPrograme(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/programmes'}`);
  }

  addProgramme(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/programme', item);
  }
  editPrograme(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-programme/${id}`, item);
  }

  deleteProgramme(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/programme/${id}`);
  }


  //end


  // Action
  fetchDataAction(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/actions'}`);
  }


  fetchDataSousActivité(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/pta-sousactivite'}`);
  }

  addAction(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/action', item);
  }
  editAction(id: any, item: any): Observable<any> {
    return this.http.put(environment.apiUrl + `operation/action/${id}`, item);
  }

  deleteAction(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/action/${id}`);
  }

  //end


  // Projet

  fetchDataProjet(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/projets'}`);
  }



  addProjet(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/projet', item);
  }
  editProjet(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-projet/${id}`, item);
  }

  deleteProjet(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/projet/${id}`);
  }
  //end

  //Indicateurs

  fetchDataIndicateur(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/indicateurs'}`);
  }
  savePlanificationToIndicateur(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/valeur-cible ', item);
  }

  updateValeurcibleTorealiser(item: any): Observable<any> {
    return this.http.put(environment.apiUrl + 'operation/update-indicateur ', item);
  }

  // get cible des indicateurs 

  getCibleIndicateurs(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/valeurs-cibles'}`);
  }
  //end 
  addIndicateurs(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/indicateur', item);
  }

  editIndicateurs(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-indicateur/${id}`, item);
  }

  deleteIndicateur(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/indicateur/${id}`);
  }

  deleteCibleIndicateur(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/remove-valeur/${id}`);
  }

  updateCible(id: any, item: any): Observable<any> {
    return this.http.put(environment.apiUrl + `operation/update-valeur-cible`, item);
  }



  //end



  fetchDataDirection(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/directions'}`);
  }

  addDirection(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'params/direction', item);
  }

  editDirection(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `params/change-direction/${id}`, item);
  }

  deleteDirection(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `params/direction/${id}`);
  }

  //

  fetchDataService(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/services'}`);
  }

  addService(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'action/status-transaction', item);
  }

  editService(id: any, item: any): Observable<any> {
    return this.http.get(environment.apiUrl + `params/models-user/${id}`, item);
  }

  deleteService(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + `params/models-user/${id}`);
  }

  fetchDataGestionUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/nationalities'}`);
  }




  sendFile(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/read-file', item);
  }

  getValidation(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/programmes'}`);
  }

  //Prohragemme


  getProgramme(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/nationalities'}`);
  }

  getHistogrammeData(): Observable<any> {
    const requestdata = {

    }
    return this.http.post(environment.apiUrl + 'params/data', requestdata);
  }

  fetchTypeIndicateur(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/type_indicateurs'}`);
  }



  fetchDataRessource(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/ressources'}`);
  }

  addRessource(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/ressource', item);
  }

  editRessource(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-ressource/${id}`, item);
  }

  deleteRessource(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/ressource/${id}`);
  }

  deleteLocalisation(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/localisation-ressource/${id}`);
  }
  fetchDataTypeResource(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/types-ressources'}`);
  }


  // centre
  getacteurs(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/acteurs'}`);
  }

  addActeur(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/acteur', item);
  }

  fetchDatacentre(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/centres'}`);
  }

  addCentre(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'operation/centre', item);
  }

  editCentre(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-centre/${id}`, item);
  }

  deleteCentre(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/centre/${id}`);
  }
  deleteActeur(id: any): Observable<any> {
    const requestdata = {
      acteur_id : id
    }
    return this.http.post(environment.apiUrl + `operation/remove-acteur`,requestdata);
  }
  fetchDataTypeArrondissement(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/arrondissements'}`);
  }
  editActeur(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/update-acteur`, item);
  }

  geListUsers(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'auth/users-liste'}`)
  }

  getStatDashboard(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/statistique-indicateurs'}`);
  }


  getExercice(): Observable<any> {


    return this.http.get(`${environment.apiUrl + 'params/exercices'}`);
  }


  getGroup(): Observable<any> {


    return this.http.get(`${environment.apiUrl + 'params/groupes'}`);
  }

  getStatsDash2(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/statistique-dashboard'}`);
  }


  // getActivité

  getSousActivité(item: any): Observable<any> {


    return this.http.get(`${environment.apiUrl + 'params/exercices'}`);
  }

  //

  getSubstanceMinerales(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/filtre-ressouces'}`, item
    );
  }

  getStatsDepartement(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/departements'}`);
  }

  getCommuneByDepartement(id:any): Observable<any> {
    return this.http.get(environment.apiUrl + `params/communes/${id}`);
  }

  getLocalisationByRessource(id:any): Observable<any> {
    const requestData ={
      type_operation:"data"
    }
    return this.http.post(environment.apiUrl + `operation/localisation-by-ressource/${id}`,requestData);
  }

  getArrondissementById(id:any): Observable<any> {
    return this.http.get(environment.apiUrl + `params/arrondissements/${id}`);
  }
  getStatutRessource(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'params/statut-ressources'}`);
  }

  saveLocalisation(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/localisation-ressource'}`, item
    );
  }
  UpdateLocalisation(localisationRef: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-localisation-ressource/${localisationRef}`, item);
  }

  // activite to projet 


  getActiviteToProjet(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-activites-projet'}`, requestdata
    );
  }
  saveActiviteToProjet(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-activite'}`, item
    );
  }

  UpdateActiviteToProjet(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-pta-activite/${id}`, item);
  }
  deleteActiviterToProjet(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/pta-activite/${id}`);
  }

  //end


  // sousActiviterToActiviter

  getSousActiviteToActivite(item: any): Observable<any> {
    const requestdata = {
      "pta_activite_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-activite'}`, requestdata
    );
  }

  saveSousActiviteToActivite(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivite'}`, item
    );
  }

  UpdateSousActiviteToActivite(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-pta-sousactivite/${id}`, item);
  }
  deleteSousActiviteToActivite(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/pta-sousactivite/${id}`);
  }
  //end

  //tacheToSousActivite


  getTacheToSousActivite(item: any): Observable<any> {
    const requestdata = {
      "pta_sous_activite_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-taches-sousactivite'}`, requestdata
    );
  }

  saveTacheToSousActivite(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-tache'}`, item
    );
  }

  UpdateTacheToSousActivite(id: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/change-pta-tache/${id}`, item);
  }
  deleteTacheToSousActivite(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/pta-tache/${id}`);
  }
  //end



  // getsosuactivitete to direction

  getSousActiviteToDirection(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-direction'}`, item
    );
  }

  saveSousActiviteDirection(reference: any, item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `operation/update-pta-tache/${reference}`, item);
  }



  // getsousActiveteToProjetByUser
  getSousActiviteToProjet(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-engaged'}`, item
    );
  }


  getSousActiviteToProjetNonEngage(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-notengaged'}`, item
    );
  }
  //end 

  // getsousActivitégag"

  getSousActiviteEngager(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-projet'}`, requestdata
    );
  }
  //end

  //ordonnancement

  getOrdonnancement(item: any): Observable<any> {

    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-ordonnanced'}`, item

    );
  }
  //end
  //getEncaissement

  getEncaissement(item: any): Observable<any> {

    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-encaissed'}`, item

    );
  }
  //end
  //

  getNonOrdonnancement(item: any): Observable<any> {


    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-notordonnanced'}`, item

    );
  }

  getDecaissement(item: any): Observable<any> {


    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivites-notencaissed'}`, item

    );
  }
  //end


  //save Ordonnancemnt

  saveActiviteOrdonnacement(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/ordonnancement'}`, item
    );
  }
  saveActiviteEncaissement(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/encaissement'}`, item
    );
  }

  saveEngagement(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/engagement'}`, item);
  }

  //
  getTache(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/pta-taches'}`);
  }//end getTache

  //liste des taches par sous activite
  getTacheListBySousActiviteItem(sousActiviteId: string): Observable<any> {
    let item: any = { pta_sous_activite_id: sousActiviteId };
    return this.http.post(`${environment.apiUrl + 'operation/pta-taches-sousactivite'}`, item);
  }//end getTacheListBySousActiviteItem


  //statistique 

  getStatsProjet(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/projets'}`
    );
  }

  getProjetByUser(item: any): Observable<any> {
    const requestdata = {
      "user_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/user-projects'}`, requestdata
    );
  }

  getPtaActivite(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-activite-projet'}`, requestdata
    );
  }


  getPtaTacheProjet(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-taches-projet'}`, requestdata
    );
  }

  getPtaSousactivite(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/pta-sousactivite-projet'}`, requestdata
    );
  }

  saveTacheToProjet(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/projet-directions'}`, item
    );
  }
  //end


  getDirection(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/directions-projets'}`);
  }


  deleteProjetToDirection(id: any): Observable<any> {
    const request = {
      "reference": id
    }
    return this.http.post(environment.apiUrl + `operation/remove-direction-projet`, request);
  }


  // annulation de l'engagement 
  annulationEngagement(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/engagement/${id}`);
  }
  // end

  // annulation de l'ordonnancement 
  annulationOrdonnancement(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/ordonnancement/${id}`);
  }
  // end annulationOrdonnancement
  annulationEncaissement(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `operation/encaissement/${id}`);
  }

  // tache  realiser a realiser en retard
  getTacheArealiser(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/pta-taches-to-achieve'}`, item
    );
  }// end getTacheArealiser

  //liste des projets utilisateurs
  getListUserProjects(reference: any): Observable<any> {

    return this.http.get(`${environment.apiUrl + 'operation/user/' + reference + '/projects'}`,
    );
  }// end getListUserProjects

  //liste projets par direction
  getProjetByDirection(item: any): Observable<any> {
    const requestdata = {
      "direction_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/projets-direction'}`, requestdata
    );
  } //end getProjetByDirection

  //liste directions par projet
  getDirectionsByProjet(item: any): Observable<any> {
    const requestdata = {
      "projet_id": item
    }
    return this.http.post(`${environment.apiUrl + 'operation/directions-projet'}`, requestdata
    );
  } //end getDirectionsByProjet

  // gettache to projet to direction to exercice
  getTacheArealiserRealiserEnRetard(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/projets-direction'}`, item);
  }

  //statistiques budgetaire
  getStatistiqueTepActiviteBudgetaire(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    }
    return this.http.post(`${environment.apiUrl + 'operation/tep-activite-budgetaire'}`, requestData);
  }//end getStatistiqueTepActiviteBudgetaire

   //get statistiques tep operationnelle
   getStatistiqueTepProjet(item: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
      let requestData =  { "annee": item, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef };
      return this.http.post(`${environment.apiUrl + 'operation/tep-projets'}`, requestData);
  }

  //get statistiques tep operationnelle
  getStatistiqueTepOperationnelle(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tep-activite-operationnelle'}`, requestData);
  }//end getStatistiqueTepOperationnelle

  getStatistiqueTepGlobalObgrm(item: any , dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
      requestData = { "annee": item, "date_debut": dateDebut, "date_fin": dateFin,  "user_ref": userRef }
    return this.http.post(`${environment.apiUrl + 'operation/montants-projets'}`, requestData);
  }


  
  getStatistiqueTepBilanPta(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_ref": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    }

    return this.http.post(`${environment.apiUrl + 'operation/bilan-financier-projet'}`, requestData);
  }

  getStatistiqueRapportPerformance(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_ref": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    }

    return this.http.post(`${environment.apiUrl + 'operation/projet-tep'}`, requestData);
  }

  getTepByMonth(item:any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
      requestData = { "annee": item, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }

    return this.http.post(`${environment.apiUrl + 'operation/tep-annuel'}`, requestData);
  }
  getStatistiqueTableauDeBordIndicateur(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, userRef: string): Observable<any> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item,  "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    } else {
      requestData = { "annee": item,  "date_debut": dateDebut, "date_fin": dateFin, "type_operation": "data", "user_ref": userRef }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tableau-bord-indicateurs'}`, requestData);
  }

  //printStatistiqueTepOperationnelleAsPdf
  printStatistiqueTepOperationnelleAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tep-activite-operationnelle'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueTepOperationnelleAsPdf

  //printStatistiqueTepProjetAsFile
  printStatistiqueTepProjetAsFile(item: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }

    return this.http.post(`${environment.apiUrl + 'operation/tep-projets  '}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueTepProjetAsFile

  printStatistiqueTepGlobalObgrmAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tep-projets  '}`, requestData, {
      responseType: 'blob'
    });
  }
  // printStatistiqueMontant(item: any, projet: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
  //   let requestData = {};
  //   if (projet !== null) {
  //     requestData = { "annee": item,   "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
  //   } else {
  //     requestData = { "annee": item, "direction_id":  dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
  //   }

  //   return this.http.post(`${environment.apiUrl + 'operation/montants-projets'}`, requestData, {
  //     responseType: 'blob'
  //   });
  // }
  
  //end printStatistiqueTepGlobalObgrmAsFile

  printStatistiqueTableauxDeBordAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tableau-bord-indicateurs'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueTableauxDeBordAsFile

  printStatistiqueBilanAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/bilan-pta'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueBilanAsFile

  printStatistiqueRapportPerformanceAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/rapport-performance'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueBilanAsFile
  downloadPdf(item: any): Observable<Blob> {
    return this.http.post(`${environment.apiUrl + 'operation/exportation-pdf'}`, item, {
      responseType: 'blob'
    });
  }


  downloadPdfByLocalisationRessource(id:any): Observable<Blob> {
    return this.http.get(environment.apiUrl + `operation/localisation-rapport/${id}`,
      {
        responseType: 'blob'
      }
    );

  }

  //printStatistiqueTepBudgetaireAsFile
  printStatistiqueTepBudgetaireAsFile(item: any, projet: any, direction: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any): Observable<Blob> {
    let requestData = {};
    if (projet !== null) {
      requestData = { "annee": item, "projet_id": projet, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    } else {
      requestData = { "annee": item, "direction_id": direction, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference }
    }

    return this.http.post(`${environment.apiUrl + 'operation/tep-activite-budgetaire'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueTepBudgetaireAsFile

  //cancel tache
  annulerTache(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/cancel-pta-tache'}`, item);
  }
  //end


  printStatistiqueTepProjetDetail(item: any, dateDebut: any, dateFin: any, typeDoc: any, userReference: any,data:any): Observable<Blob> {
    let requestData = {};
    requestData = { "annee": item, "date_debut": dateDebut, "date_fin": dateFin, "type_operation": typeDoc, "user_ref": userReference,"data":data }

    return this.http.post(`${environment.apiUrl + 'operation/tep-details'}`, requestData, {
      responseType: 'blob'
    });
  }//end printStatistiqueTepProjetDetail

  getStatsEnRetard(): Observable<any> {
    
    return this.http.get(`${environment.apiUrl + 'params/statistique-taches'}`);
  }

  getStatsIndicateur(): Observable<any> {    
    return this.http.get(`${environment.apiUrl + 'operation/indicateurs'}`);
  }


  
  getIndicateurCle(item: any): Observable<any> {

    return this.http.post(`${environment.apiUrl + 'operation/tep-annuel'}`, item

    );
  }


  getMontantByProjet(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + 'operation/montants-projets'}`, item
    );
  }
}



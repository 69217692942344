<div class="container-fluid">

    <h4 id="upper">tep projets ({{projetData.length}})</h4>
    <div class="d-flex justify-content-end">

        <!-- Ajout des champs de date -->
        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Début:
                <input disabled type="date" class="form-control ms-2" [(ngModel)]="dateDebut" name="dateDebut">
            </label>
        </div>

        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Fin:
                <input type="date" class="form-control ms-2" [(ngModel)]="dateFin" name="dateFin">
            </label>
        </div>



        <div>
            <div class="me-3">
                <div id="tickets-table_filter" class="dataTables_filter">
                    <input (click)="getStatistique()" type="button" value="Rechercher" class=" btn btn-primary">
                </div>
            </div>

        </div>
        <div class="dropdown d-inline-block " ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn btn-primary dropdown-toggle" id="dropdownMenuButton"
                aria-haspopup="true" aria-expanded="false">
                Exporter <i class="pi pi-upload" style="font-size: 0.8rem"></i>

            </button>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('pdf')">Au format
                    PDF</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('excel')">Au format
                    Excel</a>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">

                <div class="card-body">

                    <div class="table-responsive mt-3">
                        <table class="table table-centered datatable dt-responsive nowrap"
                            style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                            <thead class="thead-light  rwd-table">
                                <tr>
                                    <th>Code</th>
                                    <th>Libellé</th>
                                    <th>Poids</th>
                                    <th style="white-space: nowrap;">TEP Prévisionnel</th>
                                    <th style="white-space: nowrap;">TEP Réalisé</th>
                                    <th style="white-space: nowrap;">Ecart</th>
                                    <th>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paginatedProjets; let index = index">
                                    <td>{{item?.code}}</td>
                                    <td>{{item?.libelle}}</td>
                                    <td>{{item?.poids}}</td>
                                    <td>{{ item?.tep_prevu }}</td>
                                    <td>{{ item?.tep_realise }}</td>
                                    <td>{{ item?.ecart }}</td>
                                    <td>
                                        <button class="btn btn-success btn-sm" (click)="detailsProjet(item)"
                                            title="Cliquez ici pour voir les détails">Détails</button>
                                    </td>
                                </tr>

                                <tr *ngIf="projetData.length === 0 && isLoading === false">
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="text-center">
                                                <h5 id="graycolor">Aucune donnée trouvée</h5>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                        <div class="container">
                            <div class="row">
                                <div class="text-center">
                                    <h5 id="graycolor">Chargement en cours</h5>
                                    <!-- <div class="loader4"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets"
                        [rows]="rowsProjets" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                    </p-paginator>
                </div>
            </div>

        </div>

    </div>
</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';


import { ChartType, Stat, Chat, Transaction } from './dashboard.model';

import { ParameterService } from 'src/app/shared/service/params/param';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponentRecherche implements OnInit {

  statData: Stat[];

  ressource:any
  centre:any
  materiaux:any
  centreItems: any = [];
  stats: any = [];

  typeRessourece1 :any;
  typeRessourece2 :any;
  typeRessourece3 :any;
  markers: { lat: number, lng: number, type:any, data:any, }[] = [];

  constructor(
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private markerDataService: MarkerDataService,


  ) {
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }






  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  ngOnInit(): void {


    this.getStatRessource();
    this.getCentreData();
    this.getRessource();

  }



  getStatRessource() {

    this.paramservice.getStatsDash2().subscribe(

      (response: any) => {
        this.stats = response.data;

        this.ressource = this.stats?.ressources;
        this.centre = this.stats?.centres;
        this.materiaux = this.stats?.Materiaux_Construction;

        this.typeRessourece1 = this.stats?.Substance_minerales
        this.typeRessourece2 = this.stats?.Pierres_orientales
        this.typeRessourece3 = this.stats?.Pierre_fines
      },
      (error) => {

      }

    )
  }

  getCentreData() {
    this.paramservice.fetchDatacentre().subscribe(
      (response: any) => {
        this.centreItems = response.data;
        this.updateMarkers();  // Met à jour les marqueurs
      },
      (error) => {
      }

    )

  }
  items: any = [];

  getRessource() {

    this.paramservice.fetchDataRessource().subscribe(
      (response: any) => {
        this.items = response.data;
        this.updateMarkers();
      },
      (error) => {
      }
    )
  }
  updateMarkers() {
    const combinedMarkers = [];

    // Ajouter les centres comme marqueurs
   // console.log("this.centreItems",this.centreItems);

    this.centreItems.forEach(centre => {
      let dataCentre: any = {
        nom: centre.nom,
        items: centre,
        description: centre.description,
        type: 'valorisation'
      };

      combinedMarkers.push({
        lat: centre.latitude,
        lng: centre.longitude,
        data: dataCentre,
        type: 'valorisation'
      });
    });

    // Ajouter les ressources comme marqueurs
    this.items.forEach(ressource => {
      ressource.localisation_ressource.forEach(localisation => {

        let dataRessource: any = {
          ressource: ressource.titre,
          departement: localisation.arrondissement.commune.departement.libelle,
          description: ressource.perspective,
          type_ressource: ressource.type_ressource.libelle,
          type: 'ressource'  ,
          logo: ressource.icone,
          communes: localisation.arrondissement.commune.libelle,
          localite: localisation.nom_localite,
          resume: localisation.resume_recherche,
          rapport_recherche: localisation.rapport_recherche,
          reference: localisation.reference,
        };

        combinedMarkers.push({
          lat: localisation.latitude,
          lng: localisation.longitude,
          data: dataRessource,
          type: 'ressource'
        });
      });
    });

    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(combinedMarkers);
  }
}

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Action } from '@fullcalendar/core/internal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { first } from 'rxjs/operators';
import { reference } from '@popperjs/core';
import { PtaSousActivite } from 'src/app/shared/models/ptasousactivite';
import { Engagement } from 'src/app/shared/models/Engagement';

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrl: './engagement.component.scss'
})


export class EngagementComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;
  sousActiviteItem: PtaSousActivite ;
  isLoading: boolean = false;
  items: any = [];
  showFiltre :boolean =false;
  directionItems :any =[];
  selectedDirection: any = {};
  userInfo :any
  itemsNonEngager: any = [];
  modalRef: NgbModalRef | null = null;
  item: Engagement = new Engagement();
  itemToDelete!: Action;
  name: any;

  itemsListCopy: any[] = []; 
  projetItems: any = []; 
  annee: any ; 
  selectedProjet:any;
  directionId: any;
  @ViewChild('engagementModal') modalContent: TemplateRef<any>;
  projetId: any;
  firstProjets = 0;
  rowsProjets = 10;
  paginatedProjets = [];
  totalRecords: number = 0; // Nombre total d'éléments
  firstActivites = 0;
  rowsActivites = 10;
  paginatedActivites = [];

  totalRecords1: number = 0; // Nombre total d'éléments

  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxservice: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();
    this.userInfo =this.localstorage.getInfoUserToService();

     //listing des projets
     this.getProjetsList();

     this.getTacheEngagementNonEngagement();
    if (this.userInfo?.data?.groupe === "admin") {
      this.showFiltre = true;
      this.getDirection();
    }

    if (this.userInfo?.data?.groupe === "point_focal") {
      this.directionId =this.localstorage.getInfoUserToService()?.data?.direction_id;
      this.getProjetByDirection(this.directionId?.data?.direction_id);
    }
  }

   //process selected Projet
   processSelectedProjet(){

    //get sous activites associated
    this.getTacheEngagementNonEngagement();

    //get direction associated
    this.getDirectionByProjet(this.selectedProjet
    );
  }//end processSelectedProjet


  //listing des projets
  getProjetsList() {
    this.paramservice.getListUserProjects(this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;
      },
      (error) => {
      }
    )
  }//end getProjetsList


   //list direction par projet
   getDirectionByProjet(reference: any) {
    this.paramservice.getDirectionsByProjet(reference).subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error: any) => {
      }
    )
  }//end getProjetByDirection

    //filtre les donnees par direction
    filterDataByDirections(){
      if(this.selectedDirection === null || this.selectedDirection === undefined || this.selectedDirection.direction_id === undefined) {
        this.items = this.itemsListCopy
      }else{
        this.items = this.itemsListCopy.filter(x => x.direction_id === this.selectedDirection.direction_id);
      }  
    }//en filterDataByDirections


  getSousActiviteToUserEngager(item :any) {
    this.items = [];
    this.isLoading = true;
    //this.ngxservice.start();

    this.paramservice.getSousActiviteToProjet(item).subscribe(

      (response: any) => {
        this.isLoading = false;
        this.ngxservice.stop();
        this.items = response.data;

        this.totalRecords = this.items.length;
        this.updatePaginatedProjets();

      },
      (error) => {
        this.ngxservice.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:"une erreur est survenu lors de la recuperation de la liste des engagements", life: 3000 });
      }
    );
  }//end getSousActiviteToUser

  
  getSousActiviteToUserNonEngager(item:any) {
    this.itemsNonEngager = [];
    this.isLoading = true;
    this.paramservice.getSousActiviteToProjetNonEngage(item).subscribe(

      (response: any) => {
        this.isLoading = false;
        this.itemsNonEngager = response.data;
        this.totalRecords1 = this.itemsNonEngager.length
        this.updatePaginatedActivites();
        this.ngxservice.stop();

       // this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail:response.message, life: 3000 });
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:"une erreur est survenu lors de la recuperation de la liste des engagements", life: 3000 });
      }
    );
  }//end getSousActiviteToUser

  DetailEngagement(sousActivite: PtaSousActivite) {
    // Ouvrir le modal en passant le template
    if (sousActivite instanceof PtaSousActivite) {
      this.sousActiviteItem = sousActivite;
    } else {
        this.sousActiviteItem = Object.assign(new PtaSousActivite(), sousActivite);
    }

    //this.item.pta_sous_activite_id = sousActivite.reference;
    this.modalService.open(this.modalContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }//end DetailEngagement

  //get list fo pelpelelel
  saveEngagement() {
    if (!this.sousActiviteItem.canSaveEngagement()) {
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: this.sousActiviteItem.getErrorMessage() });
      return;
    } else {
      this.ngxservice.start();
      this.paramservice.saveEngagement(this.sousActiviteItem).subscribe(
        (response: any) => {
          this.ngxservice.stop();
          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail:response.message });
          this.modalService.dismissAll();
          this.getTacheEngagementNonEngagement();
        },
        (error) => {
          this.ngxservice.stop();
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error });
        }
      )
    }
  }//end saveEngagement

  confirm1(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Êtes-vous sûr de vouloir continuer?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui', 
      rejectLabel: 'Non', 
      accept: () => {
        this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Vous avez acepter' });
        this.paramservice.deleteAction(event).subscribe(
          (response: any) => {
          },
          (error) => {
          },
        )
      },
    });
  }//end confirm1


    // Méthode pour mettre à jour les données paginées des projets
    updatePaginatedProjets() {
      const start = this.firstProjets;
      const end = start + this.rowsProjets;
      this.paginatedProjets = this.items.slice(start, end);
    }

    onPageChangeProjets(event) {
      this.firstProjets = event.first;
      this.rowsProjets = event.rows
      this.updatePaginatedProjets();
    }

    updatePaginatedActivites() {
      const start = this.firstActivites;
      const end = start + this.rowsActivites;
      this.paginatedActivites = this.itemsNonEngager.slice(start, end);
     }
    onPageChangeActivites(event) {
      this.firstActivites = event.first; // event.first représente le premier index de la page
      this.rowsActivites = event.rows;   // event.rows représente le nombre de lignes par page
      this.updatePaginatedActivites();
    }

    //
    AnnulerEngagement(event: any) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir annuler cet engagement :' + event.sous_activite + '?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', 
        rejectLabel: 'Non', 
        accept: () => {
          this.ngxservice.start();
          this.paramservice.annulationEngagement(event.reference).subscribe(
            (response: any) => {
              this.ngxservice.stop();
              this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'L\'engagement a été annulé' });
              this.getTacheEngagementNonEngagement()
            },
            (error) => {
              this.ngxservice.stop();
              this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error, life: 3000 });
            },
          )
        },
      });
    }

   
  
    getDirection(){
  
      this.paramservice.fetchDataDirection().subscribe(
        (response:any)=>{
          this.directionItems = response.data;
        },
        (error)=>{
        }
      )
    }
    getProjetByDirection(reference:any){
      this.paramservice.getProjetByDirection(reference).subscribe(
        (response :any)=>{
          this.projetItems = response.data;
        },
        (error:any)=>{
        }
      )
    }
  
    
  getTacheEngagementNonEngagement(){
    this.ngxservice.start();
 
    const request = {
      "user_ref": this.userInfo?.data?.reference,
      "projet_id": this.selectedProjet,
      "annee": this.annee,
    }
    
    this.getSousActiviteToUserEngager(request);
    this.getSousActiviteToUserNonEngager(request);
 
  }
    
}


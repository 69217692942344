import { BehaviorSubject } from "rxjs";
import { MENU } from "src/app/admin/layouts/shared/horizontalnavbar/menu";
import { MenuItem } from "src/app/admin/layouts/shared/horizontalnavbar/menu.model";

export class FecthDataService {
    private ServiceCreatedSubject = new BehaviorSubject<boolean>(false);
    actionCreated$ = this.ServiceCreatedSubject.asObservable();
  
    notifySeriviceCreated() {
      this.ServiceCreatedSubject.next(true);
    }
  }

  export class MenuService {
    private menuItemsSubject = new BehaviorSubject<MenuItem[]>([]);
    public menuItems$ = this.menuItemsSubject.asObservable();
  
    constructor() {}
  
    updateMenu(userGroup: string, userDirection: string, accessExecution: any): void {
      // Filtrage des menus en fonction de l'utilisateur
      console.log("userGroup", userGroup);
      console.log("userDirection", userDirection);
      console.log("accessExecution", accessExecution);
    
      const filteredMenu = MENU.map(item => {
        // Si accessExecution est null, ne pas afficher les menus 'Exécution' et 'Edition'
        if (accessExecution === null && (item.label === 'Exécution' || item.label === 'Edition')) {
          return null; // Exclure cet item
        }
    
        // Vérifier les rôles globaux
        if (item.roles && !item.roles.includes(userGroup)) {
          return null; // Exclure cet item
        }
    
        // Si c'est le menu "Exécution" pour DAF
        if (item.label === 'Exécution' && userDirection === 'DAF') {
          let newItem = { ...item }; // Copie pour éviter les mutations
          // Garder uniquement "Engagements" et "Ordonnancements" pour DAF
          newItem.subItems = newItem.subItems?.filter(subItem => 
            subItem.label === 'Engagements' || subItem.label === 'Ordonnancements' || subItem.label === 'Decaissement'
          ).map(subItem => ({ ...subItem })); // Créer une nouvelle référence
    
          return newItem;
        }
    
        // Pour les autres items, conserver leurs sous-items si applicables
        let newItem = { ...item };
        if (newItem.subItems) {
          newItem.subItems = newItem.subItems.filter(subItem => {
            if (userDirection === 'DAF' && subItem.rolesDirection) {
              return !subItem.rolesDirection.includes('DAF');
            }
            return true;
          }).map(subItem => ({ ...subItem }));
        }
    
        return newItem;
      }).filter(item => item !== null); // Filtrer les items exclus
    
      // Mettre à jour les menus visibles
      this.menuItemsSubject.next(filteredMenu);
    }
    
    
    
  }
<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Ajouter une Ressource</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">


    <!-- Boutons d'action -->
    <div class="text-end mb-3">
        <button (click)="close()" type="button" class="btn btn-info ms-1">
            <i class="pi pi-times" style="color: white"></i> Annuler
        </button> &nbsp;
        <button (click)="addRessource()" type="submit" class="btn btn-success">
            <i class="pi pi-save" style="color: white"></i> Enregistrer
        </button>
    </div>

    <div class="text-left">
        <form>
            <!-- Nom -->
            <div class="form-group col-md-6">
                <label for="name">Index</label>
                <input [(ngModel)]="item.index" name="index" type="number" class="form-control" id="index"
                    maxlength="">
            </div>
            <div class="row mb-3">
                
                <div class="form-group col-md-6">
                    <label for="name">Nom</label>
                    <input [(ngModel)]="item.titre" name="nom" type="text" class="form-control" id="name"
                        maxlength="50">
                </div>

                <!-- Titre -->
                <div class="form-group col-md-6">
                    <label for="titre">Type de ressource</label>
                    <select name="type_ressource_id" id="type_ressource_id" class="form-select"
                        [(ngModel)]="item.type_ressource_id">
                        <option> Sélectionner un type de ressource</option>
                        <option *ngFor="let item of programmeData" [value]="item.id"> {{item.libelle}}</option>
                    </select>
                </div>
            </div>

            <!-- Description (sur toute la ligne) -->
            <div class="row mb-3">
                <div class="form-group col-md-12">
                    <label for="description">Description</label>
                    <textarea [(ngModel)]="item.perspective" name="description" class="form-control"
                        id="description"></textarea>
                </div>
            </div>

            <!-- Description Valorisation (sur toute la ligne) -->
            <div class="row mb-3">
                <div class="form-group col-md-12">
                    <label for="description">Valorisation</label>
                    <textarea [(ngModel)]="item.description_valorisation" name="description_valorisation"
                        class="form-control" id="description_valorisation"></textarea>
                </div>
            </div>

            <div class="d-flex row mb-3 ">
                <!-- Image valorisation -->
                <div class="col-md-4">
                    <div class="form-group col-md-12">
                        <label for="photo">Image Valorisation</label>
                        <input type="file" (change)="onImageValorisationFileSelected($event)" name="image_valorisation"
                            class="form-control" id="image_valorisation">
                    </div>
                </div>
                <!-- Photo -->
                <div class="col-md-4">
                    <div class="form-group col-md-12">
                        <label for="photo">Photo</label>
                        <input type="file" (change)="onPhotoSelected($event)" name="photo" class="form-control"
                            id="photo">
                    </div>
                </div>
                <!-- icone -->
                <div class="col-md-4">
                    <div class="form-group col-md-12">
                        <label for="photo">Icône</label>
                        <input type="file" (change)="onIconeSelected($event)" name="photo" class="form-control"
                            id="photo">
                    </div>
                </div>
            </div>

            <!-- Message "Aucune photo sélectionnée" -->
            <div *ngIf="!photoPreview" class="d-flex justify-content-start mt-2">
                <p>Aucune photo sélectionnée</p>
            </div>

            <!-- Aperçu de la photo -->
            <div class="row">
                <!-- Aperçu image de valorisation -->
                <div class="col-md-4" *ngIf="valorisationPreview">
                    <img [src]="valorisationPreview" alt="Aperçu de l'image de valorisation"
                        style="width: 100%; height: 150px;">
                </div>

                <!-- Aperçu photo principale -->
                <div class="col-md-4" *ngIf="photoPreview">
                    <img [src]="photoPreview" alt="Aperçu de la photo" style="width: 100%; height: 150px;">
                </div>

                <!-- Aperçu icône -->
                <div class="col-md-4" *ngIf="iconePreview">
                    <img [src]="iconePreview" alt="Aperçu de l'icône" style="width: 100%; height: 150px;">
                </div>
            </div>

        </form>
    </div>
</div>


<p-toast />
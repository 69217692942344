import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-detail-tep-projet',
  templateUrl: './detail-tep-projet.component.html',
  styleUrl: './detail-tep-projet.component.scss'
})

export class DetailTepProjetComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  submitted: boolean;
  tacheData: any = [];
  modalRef: NgbModalRef | null = null;
  @ViewChild('tacheModal') modalContent: TemplateRef<any>;
  activiteOp: any = {};
  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  fitreTache: any;
  annee: any;
  userInfo: any;
  directionId: any;
  dateDebut: string;
  dateFin: string;
  projetId: any
  itemDetail :any;
  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    this.userInfo = this.localstorage.getInfoUserToService();
    this.activiteOp = this.localstorage.getTepProjet();
    console.log(" this.activiteOp", this.activiteOp);
    
    this.dateDebut = this.localstorage.getDateToDetail()?.date_debut;
    this.dateFin = this.localstorage.getDateToDetail()?.date_fin;
    //console.log("this.dateDebut",this.dateDebut);
    
    this.itemDetail = this.localstorage.getTepProjet();
   // console.log(" this.itemDetail", this.itemDetail);
    
  }

  close() {
    this.modalService.dismissAll()
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.updateList();
  }

  updateList() {
    const start = this.first;
    const end = this.first + this.rows;
    this.paginatedItems = this.activiteOp.details.slice(start, end);
  }

  roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  //imprime en pdf et excel
  exportToDocument(typeDoc: any) {

    this.ngxService.start();

    this.paramservice.printStatistiqueTepProjetDetail(this.annee, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference,this.itemDetail).subscribe(
      (response: Blob) => {
        const filename = 'Statistiques_Activite_Projet.pdf';
        FileSaver.saveAs(response, filename);

        const url = URL.createObjectURL(response);
        window.open(url, '_blank');

        this.ngxService.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        //console.error("Une erreur est survenue", error);
        this.ngxService.stop();
      }
    );
  }//end exportToDocument



}
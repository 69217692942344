 <!-- Footer Start -->
 <div style="background-color: #364CA0;" class="py-5 wow fadeIn" data-wow-delay="0.2s">
    <div class="container ">
        <div class="row g-5">
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <img width="180px" height="180px" src="../../../assets//images/logo-obgrm-2.png" alt="Logo">
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="mb-4 text-white">Menu</h4>
                    <a routerLink="/" href="javascript: void(0);"><i class="fas fa-angle-right me-2"></i> Accueil</a>
                    <div class="nav-item dropdown"><br>
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="fas fa-angle-right me-2"></i>Présentation</a>
                        <div class="dropdown-menu m-0 ">
                            <a style="color: black;" routerLink="/apropos" href="javascript: void(0);" class="dropdown-item">Qui sommes nous?</a>
                            <a style="color: black;" routerLink="/mission" href="javascript: void(0);"  href="feature.html" class="dropdown-item">Mission</a>
                            <a style="color: black;" routerLink="/metiers" href="javascript: void(0);"  class="dropdown-item">Métiers</a>
                            <a style="color: black;" routerLink="/realisation" href="javascript: void(0);"  class="dropdown-item">Réalisations</a>
                            <a style="color: black;"routerLink="/article" href="javascript: void(0);"  class="dropdown-item">Gestion des Substances Explosives</a>

                        </div><br>
                    </div>

                    <a routerLink="/recherches"  href="javascript: void(0);"><i class="fas fa-angle-right me-2"></i> Recherche</a><br>
                    <a routerLink="/valorisation" href="javascript: void(0);"><i class="fas fa-angle-right me-2"></i> Valorisation</a><br>
                    <a routerLink="/contact" href="javascript:void(0);"><i class="fas fa-angle-right me-2"></i>Contact</a>

                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="mb-4 text-white">Espace privé</h4>
                    <a style="padding-left: 10px; padding-right: 120px; padding-top: 10px; padding-bottom: 10px;" routerLink="/login" class="button text-center" href=""> Connexion</a>

                </div>
            </div>


            <div class="col-md-6 col-lg-6 col-xl-3">
                <div class="footer-item d-flex flex-column">
                    <h4 class="text-white mb-4"><i class="fas fa-star-of-life me-3"></i>CONTACT</h4>
                    <div class="d-flex align-items-center">
                        <!-- Facebook -->
                        <a class="btn-square btn btn-primary text-white rounded-circle mx-1 d-flex justify-content-center align-items-center"
                           href="#" >
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <!-- Lindin -->
                        <a class="btn-square btn btn-primary text-white rounded-circle mx-1 d-flex justify-content-center align-items-center"
                           href="#" >
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <!-- Instagram -->
                        <!-- <a class="btn-square btn btn-primary text-white rounded-circle mx-1 d-flex justify-content-center align-items-center"
                           href="https://www.instagram.com" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a> -->
                        <!-- YouTube -->
                        <a class="btn-square btn btn-primary text-white rounded-circle mx-1 d-flex justify-content-center align-items-center"
                           href="#">
                            <i class="fab fa-youtube"></i>
                        </a>
                    </div>
                </div>
            </div>



        </div>
    </div>


</div>
<!-- Footer End -->


<!-- Back to Top -->
<a (click)="scrollToTop()"href="javascript:void(0);"class="btn btn-primary btn-lg-square back-to-top"><i class="fa fa-arrow-up"></i></a>



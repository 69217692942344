import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuiviRoutingModule } from './suivi-routing.module';
import { AddCentreComponent } from '../recherche/centres/add-centre/add-centre.component';
import { AddResourceComponent } from '../recherche/ressource/add-resource/add-resource.component';
import { TepGlobalObgrmComponent } from './edition/tep-global-obgrm/tep-global-obgrm.component';
import { TepProjetComponent } from './edition/tep-projet/tep-projet.component';
import { TepSousActivitesComponent } from './edition/tep-sous-activites/tep-sous-activites.component';
import { TepActivitesComponent } from './edition/tep-activites/tep-activites.component';
import { TableaudBordProjetComponent } from './edition/tableaud-bord-projet/tableaud-bord-projet.component';
import { TableaudBordIndicateurComponent } from './edition/tableaud-bord-indicateur/tableaud-bord-indicateur.component';
import { RapportPerformanceComponent } from './edition/rapport-performance/rapport-performance.component';
import { BilanPtaComponent } from './edition/bilan-pta/bilan-pta.component';
import { OrdonnancementComponent } from './execution/ordonnancements/ordonnancement/ordonnancement.component';
import { EngagementComponent } from './execution/engagements/engagement/engagement.component';
import { RealisationIndicateursComponent } from './execution/realisation-indicateurs/realisation-indicateurs.component';
import { EditTacheComponent } from './execution/taches/edit-tache/edit-tache.component';
import { TacheComponent } from './execution/taches/tache/tache.component';
import { DetailTacheComponent } from './execution/taches/detail-tache/detail-tache.component';
import { ActiviteHorsPtaComponent } from './planification/activite-hors-pta/activite-hors-pta.component';
import { CibleIndicateursComponent } from './planification/cible-indicateurs/cible-indicateurs.component';
import { RevueBudgetaireComponent } from './planification/revue-budgetaire/revue-budgetaire.component';
import { ValidationPtaComponent } from './planification/validation/validation-pta/validation-pta.component';
import { ImportationPtaComponent } from './planification/importation-pta/importation-pta.component';
import { EditGestionAccesComponent } from './plananalytique/gestion/edit-gestion-acces/edit-gestion-acces.component';
import { AddGestionAccesComponent } from './plananalytique/gestion/add-gestion-acces/add-gestion-acces.component';
import { GestionAccesComponent } from './plananalytique/gestion/gestion-acces/gestion-acces.component';
import { EditServiceComponent } from './plananalytique/services/edit-service/edit-service.component';
import { AddServiceComponent } from './plananalytique/services/add-service/add-service.component';
import { ServiceComponent } from './plananalytique/services/service/service.component';
import { EditDirectionComponent } from './plananalytique/directions/edit-direction/edit-direction.component';
import { AddDirectionComponent } from './plananalytique/directions/add-direction/add-direction.component';
import { DirectionComponent } from './plananalytique/directions/direction/direction.component';
import { IndicateurComponent } from './plananalytique/indicateurs/indicateur/indicateur.component';
import { EditIndicateurComponent } from './plananalytique/indicateurs/edit-indicateur/edit-indicateur.component';
import { AddIndicateurComponent } from './plananalytique/indicateurs/add-indicateur/add-indicateur.component';
import { AddProjetComponent } from './plananalytique/projet/add-projet/add-projet.component';
import { EditProjetComponent } from './plananalytique/projet/edit-projet/edit-projet.component';
import { ProjetIndicateurComponent } from './plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { EditActionComponent } from './plananalytique/actions/edit-action/edit-action.component';
import { AddActionComponent } from './plananalytique/actions/add-action/add-action.component';
import { ActionComponent } from './plananalytique/actions/action/action.component';
import { EditProgrameComponent } from './plananalytique/programs/edit-programe/edit-programe.component';
import { AddProgrameComponent } from './plananalytique/programs/add-programe/add-programe.component';
import { ProgrammeComponent } from './plananalytique/programs/programme/programme.component';
import { DashboardComponentRecherche } from '../recherche/dashboard/dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailPtaComponent } from './planification/validation/detail-pta/detail-pta.component';
import { CentreComponent } from '../recherche/centres/centre/centre.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ExecutionModule } from './execution/execution.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SimplebarAngularModule } from 'simplebar-angular';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { PagesRoutingModule } from '../../pages-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MessagesModule } from 'primeng/messages';
import { EditResourceComponent } from '../recherche/ressource/edit-resource/edit-resource.component';

import { FileUploadModule } from 'primeng/fileupload';
import { DetailProjetIndicateurComponent } from './plananalytique/projet/detail-projet-indicateur/detail-projet-indicateur.component';
import { DateFormatPipe } from 'src/app/shared/pipe/date-format.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { AddProjetToActiviteComponent } from './planification/add-projet-to-activite/add-projet-to-activite.component';
import { AddSousActivitToActiviteComponent } from './planification/add-sous-activit-to-activite/add-sous-activit-to-activite.component';
import { AddTacheSousActiviteComponent } from './planification/add-tache-sous-activite/add-tache-sous-activite.component';
import { TabViewModule } from 'primeng/tabview';
import { FormatMoneyPipe } from 'src/app/shared/pipe/format-money/format-money.pipe';
import { ProjetdirectionComponent } from './plananalytique/projetdirection/projetdirection.component';
import { AccessUserProjetComponent } from '../../account/auth/access-user-projet/access-user-projet.component';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { DetailTepActiviteOpComponent } from './edition/detail-tep-activite/detail-tep-activite-op.component';
import { DetailTepProjetComponent } from './edition/detail-tep-projet/detail-tep-projet.component';
import { PlanificationComponent } from './plananalytique/indicateurs/planification/planification.component';
import { MapsComponent } from 'src/app/maps/maps.component';
import { LimitToPipe } from 'src/app/shared/pipe/limit-to/limit-to.pipe';
import { RessourcesComponent } from '../recherche/ressource/ressources/ressources.component';
import { GraphiqueComponent } from './edition/graphique/graphique.component';
import { NgChartsModule } from 'ng2-charts';
import { TauxExecutionComponent } from './edition/taux-execution/taux-execution.component';
import { ChartModule } from 'primeng/chart';
import { IndicateurCleComponent } from './edition/indicateur-cle/indicateur-cle.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProfilComponent } from '../../account/auth/profil/profil/profil.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DecaissemntComponent } from './execution/decaissemnt/decaissemnt.component';

@NgModule({
  declarations: [

    DashboardComponent,
    // DashboardComponentRecherche,
    ProgrammeComponent,
    AddProgrameComponent,
    EditProgrameComponent,
    ActionComponent,
    AddActionComponent,
    EditActionComponent,
    ProjetIndicateurComponent,
    AddProjetComponent,
    EditProjetComponent,
    AddIndicateurComponent,
    EditIndicateurComponent,
    IndicateurComponent,
    DirectionComponent,
    AddDirectionComponent,
    EditDirectionComponent,
    ServiceComponent,
    AddServiceComponent,
    EditServiceComponent,
    GestionAccesComponent,
    AddGestionAccesComponent,
    EditGestionAccesComponent,
    ImportationPtaComponent,
    ValidationPtaComponent,
    DetailPtaComponent,
    RevueBudgetaireComponent,
    CibleIndicateursComponent,
    ActiviteHorsPtaComponent,
    DetailTacheComponent,
    TacheComponent,
    EditTacheComponent,
    RealisationIndicateursComponent,
    EngagementComponent,
    OrdonnancementComponent,
    RessourcesComponent,
    CentreComponent,
    BilanPtaComponent,
    RapportPerformanceComponent,
    TableaudBordIndicateurComponent,
    TableaudBordProjetComponent,
    TepActivitesComponent,
    TepSousActivitesComponent,
    TepProjetComponent,
    TepGlobalObgrmComponent,
    AddResourceComponent,
    AddCentreComponent,
    EditResourceComponent,
    DetailProjetIndicateurComponent,
    DateFormatPipe,
    AddProjetToActiviteComponent,
    AddSousActivitToActiviteComponent,
    AddTacheSousActiviteComponent,
    ProjetdirectionComponent,
    DetailTepActiviteOpComponent,
    DetailTepProjetComponent,
    PlanificationComponent,
    GraphiqueComponent,
    TauxExecutionComponent,
    IndicateurCleComponent,
    DecaissemntComponent
    // MapsComponent,
    //FormatMoneyPipe,
    // LimitToPipe,

  ],
  imports: [
    CommonModule,
    SuiviRoutingModule,
    CommonModule,
    UiModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    NgPipesModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    DndModule,
    FullCalendarModule,
    LeafletModule,
    WidgetModule,
    SimplebarAngularModule,
    TabViewModule,
    ExecutionModule,
    ConfirmDialogModule,
    ToastModule,
    ButtonModule,
    NgxUiLoaderModule,
    MessagesModule,
    FileUploadModule,
    PaginatorModule,
    SharedModule,
    NgChartsModule,
    ChartModule,
    SkeletonModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })

  ],

})

export class SuiviModule { }

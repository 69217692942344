import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { Ordonnancement } from 'src/app/shared/models/ordonnancement';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { reference } from '@popperjs/core';
import { first } from 'rxjs/operators';
import { PtaSousActivite } from 'src/app/shared/models/ptasousactivite';

@Component({
  selector: 'app-ordonnancement',
  templateUrl: './ordonnancement.component.html',
  styleUrl: './ordonnancement.component.scss'
})

export class OrdonnancementComponent {


  submitted: boolean;

  actionData: any = [];
  modalRef: NgbModalRef | null = null;
  item: any = {};
  itemToDelete!: any;
  @ViewChild('ordonnancementModal') modalContent: TemplateRef<any>;
  items: PtaSousActivite[] = [];
  itemNonOrdonancer: any;
  projetId: any;
  isLoading: boolean = false;
  selectedDirection: any = {};


  paginatedItems: PtaSousActivite[] = [];

  firstOrdonnancement = 0;
  rowsOrdonnancement = 10;
  paginatedOrdonnancement: PtaSousActivite[] = [];
  totalRecords: number = 0; // Nombre total d'éléments
  itemsListCopy: any[] = []; 

  firstNonOrdonnanceemnt = 0;
  rowsNonOrdonnancement = 10;
  paginateNonOrdonnancement = [];
  totalRecords2: number = 0; // Nombre total d'éléments
  showFiltre :boolean =false;
  directionItems :any =[];
  userInfo :any;

  ExerciceItems: any = []; 
  projetItems: any = []; 
  annee: any ; 
  selectedProjet:any;
  directionId: any;
  sousActiviteItem: PtaSousActivite = new PtaSousActivite();


  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxservice: NgxUiLoaderService
  ) { }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();
    this.userInfo =this.localstorage.getInfoUserToService();


    this.getTacheOrdonnacementNon();
    this.getProjetsList();

    if (this.userInfo?.data?.groupe === "admin") {
      this.showFiltre = true;
      this.getDirection();
    }
    if (this.userInfo?.data?.groupe === "point_focal") {
      
      this.directionId =this.localstorage.getInfoUserToService()?.data?.direction_id;
      
      this.getProjetByDirection(this.directionId);
    }
    this.calculateReste();
  }//end 


  getProjetsList() {
    this.paramservice.getListUserProjects(this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;
        //console.log(this.projetItems);
      },
      (error) => {
      }
    )
  }//end getProjetsList

  getDirectionByProjet(reference: any) {
    this.paramservice.getDirectionsByProjet(reference).subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error: any) => {
      }
    )
  }//end getProjetByDirection

  processSelectedProjet(){
    //get sous activites associated
    this.getTacheOrdonnacementNon();

    //get direction associated
    this.getDirectionByProjet(this.selectedProjet.reference);
  }
  getOrdonnancement(item:any) {
    this.isLoading = true;
    this.ngxservice.start();
    this.paramservice.getOrdonnancement(item).subscribe(
      (response: any) => {
        this.items = response.data;
        this.totalRecords = this.items.length; // Met à jour le total des enregistrements
        this.ngxservice.stop();
        this.isLoading = false;
        this.updatePaginatedOrdonnancement();
      },
      (error) => {
        this.ngxservice.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:"une erreur est survenu lors de la recuperation de la liste des ordonnancements", life: 3000 });
      }
    );
  }

    getNonOrdonnancement(item:any) {
      this.isLoading = true;

      this.paramservice.getNonOrdonnancement(item).subscribe(

        (response: any) => {
          this.ngxservice.stop()

          this.isLoading = false;
          this.itemNonOrdonancer = response.data;
          this.paginateNonOrdonnancement = this.itemNonOrdonancer;
          this.totalRecords2 = this.itemNonOrdonancer.length
          this.updatePaginatedNonOrdonnancement()
        },
        (error) => {
          this.ngxservice.stop()
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:"une erreur est survenu lors de la recuperation de la liste des ordonnancements", life: 3000 });
        }
      );
    }



  openModal(sousActivite: PtaSousActivite) {
    if (sousActivite instanceof PtaSousActivite) {
      this.sousActiviteItem = sousActivite;
    } else {
        this.sousActiviteItem = Object.assign(new PtaSousActivite(), sousActivite);
    }
  
    this.modalService.open(this.modalContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }//end openModal

  //enregistrement ordonnancement
  saveOrdonnancement() {

    if (!this.sousActiviteItem.canOrdonnance()) {
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: this.sousActiviteItem.getErrorMessage() });
      return;
    } 
    
    if (this.sousActiviteItem.total_engage < (this.sousActiviteItem.total_ordonnance + this.sousActiviteItem.total_a_ordonnancer)  ) {
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: "Le montant ordonnancé ne peut pas dépasser le montant engagé."});
      return;
    } 
    else {
      this.ngxservice.start();
      this.paramservice.saveActiviteOrdonnacement(this.sousActiviteItem).subscribe(
        (response: any) => {
          
          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail:response.message });
          this.ngxservice.stop();
          this.getTacheOrdonnacementNon ()
          this.modalService.dismissAll();
        },
        (error) => {
          //  this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail:error });
          this.ngxservice.stop();
        }
      )
    }
  }//end saveOrdonnancement


  //calcul reste
  calculateReste() {
    this.sousActiviteItem.reste = 
    (Number(this.sousActiviteItem.total_engage) ?? 0) - 
    (Number(this.sousActiviteItem.total_ordonnance) ?? 0) -
    (Number(this.sousActiviteItem.total_a_ordonnancer) ?? 0);
  
  }//end calculateReste
 

  updatePaginatedOrdonnancement() {
    const start = this.firstOrdonnancement;
    const end = start + this.rowsOrdonnancement;
    this.paginatedOrdonnancement = this.items.slice(start, end);
  }

  // Méthode pour mettre à jour les données paginées des activités
  updatePaginatedNonOrdonnancement() {
    const start = this.firstNonOrdonnanceemnt;
    const end = start + this.rowsNonOrdonnancement;
    this.paginateNonOrdonnancement = this.itemNonOrdonancer.slice(start,end);
  }


  onPageChangeOrdonnacnement(event) {
    this.firstOrdonnancement = event.first
    this.rowsOrdonnancement = event.rows
    this.updatePaginatedOrdonnancement();
  }

  onPageChangeNonOrdonnanement(event) {
    this.firstNonOrdonnanceemnt = event.first
    this.rowsNonOrdonnancement = event.rows
    this.updatePaginatedNonOrdonnancement();
  }
 

  getDirection(){
    this.paramservice.fetchDataDirection().subscribe(
      (response:any)=>{
        this.directionItems = response.data;
      },
      (error)=>{
      }
    )
  }

  getProjetByDirection(reference:any){
    this.paramservice.getProjetByDirection(reference).subscribe(
      (response :any)=>{
        this.directionItems = response.data;
      },
      (error:any)=>{
      }
    )
  }

  getTacheOrdonnacementNon(){
    this.ngxservice.start()
    const request = {
      user_ref: this.userInfo?.data?.reference,
      projet_id: this.selectedProjet ? this.selectedProjet.reference : null,
      annee: this.annee,
    };
  
    this.getOrdonnancement(request);
    this.getNonOrdonnancement(request);
  }

  filterDataByDirections(){
    if(this.selectedDirection === null || this.selectedDirection === undefined || this.selectedDirection.direction_id === undefined) {
      this.items = this.itemsListCopy
    }else{
      this.items = this.itemsListCopy.filter(x => x.direction_id === this.selectedDirection.direction_id);
    }  
  }//en filterDataByDirections


    //annuler ordonnancement
    cancelOrdonnancement(event: any) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir annuler cet ordonnancement :' + event.sous_activite + '?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', 
        rejectLabel: 'Non', 
        accept: () => {
          this.ngxservice.start();
          this.paramservice.annulationOrdonnancement(event.reference).subscribe(
            (response: any) => {
              this.ngxservice.stop();
              this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'L\'engagement a été annulé' });
              this.getTacheOrdonnacementNon()
            },
            (error) => {
              this.ngxservice.stop();
              this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error, life: 3000 });
            },
          )
        },
      });
    }//end cancelOrdonnancement

}

<div class="container-fluid">

  <h4 id="upper">Indicateurs</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!--  -->
            <a href="javascript:void(0);" class="btn btn-success mb-2" id="gras" (click)="addIndicateur()">
              <i class="mdi mdi-plus me-2"></i> Ajouter un Indicateur
            </a>
          </div>
          <div class="row mb-md-2">

            <div class="d-flex justify-content-end">
              <div *ngIf="showFiltre === true" class="me-3">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <label class="d-inline-flex align-items-center">
                    Direction:
                    <select style="width: 200px;" name="directionFilter"
                    class="form-select ms-2" [(ngModel)]="selectedDirectionReference" (change)="filterProjetDirection()">
                    <option value="">Sélectionner une direction</option>
                    <option *ngFor="let item of directionItems" [value]="item.id">{{ item?.libelle}}</option>
                </select>
                  </label>
                </div>
              </div>
              <div>
                <div id="tickets-table_filter" class="dataTables_filter">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <input type="text" name="searchTerm" [(ngModel)]="searchTerm" (keyup)="filterData()"
                      class="form-control form-control-sm ms-2" aria-controls="tickets-table">
                  </label>
                </div>
              </div>
            </div>


            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light  rwd-table">
                <tr>
                  <th style="width: 20px; ">
                  </th>
                  <th style="white-space: nowrap;">Libellé</th>
                  <th style="white-space: nowrap;">Type Indicateur</th>

                  <th style="white-space: nowrap;">Valeur référence</th>
                  <th style="white-space: nowrap;">Date de référence</th>
                  <th>Unité</th>
                  <th>Projet</th>
                  <th style="width: 120px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedProjets; let index = index">
                  <td>
                  </td>
                  <td>{{item?.libelle}}</td>
                  <td>{{item?.type_indicateur?.libelle}}</td>

                  <td>{{item?.valeur_reference}}</td>
                  <td>{{item?.date_valeur_reference | date:'dd/MM/yyyy'}}</td>
                  <td>{{item?.unite}}</td>
                  <td>{{item?.projet?.libelle}}</td>
                  <td>
                    <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                      title="Modifier">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                      title="Retirer">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a> &nbsp;&nbsp;
                    <a (click)="redirectPlanification(item)" href="javascript:void(0);" class="text-info"
                      v-b-tooltip.hover title="Planifier"><i class="pi pi-hammer" style="font-size: 1rem"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center" *ngIf="isLoading === true">
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Chargement en cours...</h5>
                  <div class="loader4"></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center">
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Aucune donnée trouvée</h5>
                </div>
              </div>
            </div>

          </div>
          <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
            [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator>
        </div>
      </div>
    </div>


  </div>
</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>
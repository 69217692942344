export class Ressource {
    index: any;
    titre: any;
    type_ressource_id: any;
    photo: File;
    perspective: any;
    description_valorisation: any;
    photo_valorisation: File;
    icone: File;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    canSave(): boolean {
        if (!this.titre || this.titre.trim() === '') {
            this.errorMessage = "Le nom est obligatoire";
            return false;
        }

        if (!this.type_ressource_id ) {
            this.errorMessage = "Le type de ressource est obligatoire";
            return false;
        }
        if (!this.perspective || this.perspective.trim() === '') {
            this.errorMessage = "La perspective est obligatoire";
            return false;
        }
        if (!this.description_valorisation || this.description_valorisation.trim() === '') {
            this.errorMessage = "La description de la valorisation est obligatoire";
            return false;
        }
        if (!this.photo) {
            this.errorMessage = "Une photo est obligatoire";
            return false;
        }

        if (!this.photo_valorisation) {
            this.errorMessage = "La photo de valorisation est obligatoire";
            return false;
        }
        if (!this.icone) {
            this.errorMessage = "L'icone est obligatoire";
            return false;
        }
        this.errorMessage = ""; 
        return true;
    }
}

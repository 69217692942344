<div class="container-fluid">
  <br>
  <h4>Engagements</h4>
  <div class="d-flex justify-content-end">


    <div  class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Projets:
          <select type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedProjet" (change)="processSelectedProjet()"  >
            <option disabled value="Sélectionner une direction"></option>
            <option *ngFor="let item of projetItems" [ngValue]="item.reference"> {{ item?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>

    <div *ngIf="showFiltre === true" class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Direction:
          <select  type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedDirection"  (change)="filterDataByDirections()"   >
            <option value="Sélectionner une direction" >Sélectionner une direction</option>
            <option *ngFor="let item of directionItems" [ngValue]="item"> {{ item?.direction?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>
 

    <div>
      <div  class="me-3">
      </div>
    </div>
  </div>

  <p-tabView>
    <p-tabPanel header="Non engagés ({{itemsNonEngager ? itemsNonEngager.length : 0}})">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                </div>
                <div class="table-responsive mt-3">
                  <table class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                    <thead class="thead-light  rwd-table">
                      <tr>
                        <th>Code </th>
                        <th>Libellé</th>
                        <th>Montant planifié</th>
                        <th>Total engagé</th>
                        <th style="width: 120px;"></th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of paginatedActivites; let index = index">
                        <td>{{item?.code_sous_activite}}</td>
                        <td>{{item?.sous_activite}}</td>
                        <td>{{ item?.total
                          | formatMoney :null }}
                        </td>
                        <td>{{item.total_engage | formatMoney:null  }}</td>
                        <td>

                          <div class="d-flex align-items-center">
                            <button (click)="DetailEngagement(item)" title="Cliquer ici pour Engager cette sous-activité"
                                    class="btn btn-success btn-sm d-flex align-items-center me-2">
                              <i class="mdi mdi-checkbox-multiple-marked font-size-18 me-1"></i> Engager
                            </button>
                          
                          </div>
                          
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Chargement en cours</h5>
                        <div class="loader4"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="paginatedActivites.length === 0 && isLoading === false" class="d-flex justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                      </div>
                    </div>
                  </div>

                </div>
                <p-paginator 
                (onPageChange)="onPageChangeActivites($event)"
                [first]="firstActivites"
                [rows]="rowsActivites"
                [totalRecords]="totalRecords1"
                [rowsPerPageOptions]="[10, 20, 30]">
              </p-paginator>
              
              </div>
            </div>
          </div>
        </div>


      </div>
    </p-tabPanel>
    <p-tabPanel header="Engagés ({{items ? items.length : 0}})">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
             
                    </div>               
                   
                    
                
                <div class="table-responsive mt-3">
                  <table class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                    <thead class="thead-light  rwd-table">
                      <tr>
                        <th>Code </th>
                        <th>Libellé</th>
                        <th>Montant planifié</th>
                        <th>Total engagé</th>
                       <th style="width: 120px;">Action</th> 
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of paginatedProjets; let index = index">
                        <td>{{item?.code_sous_activite}}</td>
                        <td>{{item?.sous_activite}}</td>
                        <td>{{ item?.total |formatMoney :null  }}
                        </td>
                        <td>{{item?.total_engage |formatMoney :null  }}</td>
                        
                        <td>
                           <button (click)="AnnulerEngagement(item)" title="Cliquer ici pour annuler cette sous-activité"
                                    class="btn btn-secondary btn-sm d-flex align-items-center">
                              <i class="pi pi-minus-circle me-1"></i> Annuler
                            </button>
                        </td> 
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Chargement en cours</h5>
                        <div class="loader4"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                      </div>
                    </div>
                  </div>

                </div>
                <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
                  [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
              </div>
            </div>
          </div>
        </div>


      </div>
    </p-tabPanel>
  </p-tabView>

  <ng-template #engagementModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{sousActiviteItem.code_sous_activite}}: {{sousActiviteItem.sous_activite}} </h5>
      <button (click)="modal.dismiss('Close click')" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form>

          <div class="d-flex">
            <div class="form-group col-md-3">
              <label for="name">Code Sous Activité</label>
              <input style="background-color: aliceblue;" [(ngModel)]="sousActiviteItem.code_sous_activite" disabled
                name="code" type="text" class="form-control" id="code" placeholder=" un code">

            </div> &nbsp;
            <div class="form-group  col-md-6">
              <label for="name">Libellé Sous Activité</label>
              <input style="background-color: aliceblue; width: 575px; " [(ngModel)]="sousActiviteItem.sous_activite" name="libelle"
                disabled type="text" class="form-control" id="name" placeholder=" libelle ">

            </div>
          </div><br>

          <div class="d-flex">
            <div class="form-group col-md-6">
              <label for="email">Montant budgetisé</label>
              <input disabled [(ngModel)]="sousActiviteItem.total" name="poids_action" class="form-control" type="number"
               name="total" id="montant_budgetise_total" placeholder="Montant budgetisé">

            </div>&nbsp;

            <div class="form-group col-md-6">
              <label for="email">Montant à engager</label>
              <input  [(ngModel)]="sousActiviteItem.total_engage" name="poids_action" class="form-control" type="number"
                id="montant" placeholder="Montant à engager">

            </div>
          </div><br>

          <div class="d-flex">
            <div class="form-group col-md-6">
              <label>Date d'engagement </label>
              <input [(ngModel)]="sousActiviteItem.date_engagement" name="responsable_action" type="date" class="form-control"
                placeholder=" un Poids" minlength="10">

            </div>&nbsp;

            <div class="form-group col-md-6">
            

            </div>
          </div><br>


          <div class="text-end">
            <div class="text-end">
              <button (click)="modal.dismiss('Close click')" type="button" class="btn btn-info ms-1"> <i
                  class="pi pi-times" style="color: white"></i> Annuler</button> &nbsp;
              <button (click)="saveEngagement()" type="submit" class="btn btn-success"> <i class="pi pi-save"
                  style="color: white"></i>
                Engager</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <p-confirmDialog />
  <p-toast />
  <ngx-ui-loader></ngx-ui-loader>
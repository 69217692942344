import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Acteurs } from 'src/app/shared/models/acteurs';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-add-acteurs',
  templateUrl: './add-acteurs.component.html',
  styleUrl: './add-acteurs.component.scss',
})

export class AddActeursComponent {

  item: Acteurs = new Acteurs();
  programmeData: any = [];
  photoPreview: string | ArrayBuffer | null = null;
  departement: any = [];
  commune: any = [];
  ressource: any = [];
  selectedDepartementId: any;
  selectedCommuneId: any;
  selectedArrondissement: any;
  @ViewChild('cibleModal') modalContent: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private messageService: MessageService,
    private FecthDataService: FecthDataService,
    private localstorage: LocalStorageService,
    private ngxservice: NgxUiLoaderService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit() {
    this.getRessource();
  }

  close() {
    this.modalService.dismissAll();
  }

 
  
  addActeurs() {
    if (!this.item.canSave()) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: this.item.getErrorMessage() });
      return;
    }

    const ressourcesSelectionnees = this.ressource.filter(item => item.ressource_exploite);
  
    // Assurez-vous que vous envoyez ces ressources avec l'objet `item` si nécessaire
    this.item.ressource_exploite = ressourcesSelectionnees;
      this.ngxservice.start();
      this.paramService.addActeur(this.item).subscribe(
        (response: any) => {
          this.ngxservice.stop();
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });

          this.FecthDataService.notifySeriviceCreated();

        },
        (error: any) => {
          this.ngxservice.stop();
          //console.log("une erreur est survenu", error);
          // this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
          this.FecthDataService.notifySeriviceCreated();
          this.ngxservice.stop();


        }
      )
   // }

  }

  getRessource() {
    this.paramService.fetchDataRessource().subscribe(
      (response: any) => {
        this.ressource = response.data;
        this.ressource.forEach(item => {
          item.ressource_checked = item.ressource_checked || false;
        });
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }

  visible: boolean = false;

  position: string = 'center';

  showDialog(position: string) {
      this.position = position;
      this.visible = true;
  }
} 
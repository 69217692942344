import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})

export class ArticleComponent {

  constructor(

    private router :Router
  ){}

  goToMission(){

  //  console.log("appelr");
    
    this.router.navigate(['/mission'])
  }
}
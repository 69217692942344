<div class="container-fluid">
    <!-- Header avec le bouton de retour et le titre -->
    <div class="d-flex justify-content-between mb-3">
        <h5 id="upper" class="mb-0">Localisation</h5>
        <button routerLink="/recherche/ressource" type="submit" class="btn btn-primary">
            <i class="pi pi-arrow-left" style="font-size: 0.9rem"></i> Retour
        </button>
    </div>

    <!-- Conteneur principal avec détails, carte, et listing -->
    <div class="row">
        <!-- Colonne de gauche : Détails de la ressource -->
        <div class="col-12 col-md-3 col-lg-3 mb-3">
            <div class="card h-100">
                <div class="modal-body p-3">
                    <h5 id="upper" class="text-center">Détails de la ressource</h5>
                    <div class="text-left">
                        <div class="col-12 mb-3 mt-3">
                            <h5><b>Titre :</b> {{ressourceItem?.titre}}</h5>
                        </div>
                        <div class="col-12 mb-3  mt-3">
                            <h5><b>Type Ressource :</b> {{ressourceItem?.type_ressource?.libelle}}</h5>
                        </div>
                        <div class="col-12 mb-3  mt-3">
                            <h5><b>Description :</b> {{ressourceItem?.perspective}}</h5>
                        </div>
                        <div class="card col-12 justify-content-center d-flex mt-3">
                            <h5><b>Photo associée à la ressource</b></h5>
                            <div class="d-flex justify-content-center">
                                <img [src]="ressourceItem.photo" alt="" class="img-fluid" style="max-height: 250px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Colonne centrale : Carte -->
        <div class="col-12 col-md-3 col-lg-3 mb-3">
            <div class="card h-100">
                <div class="card-body p-0">
                    <app-maps [markers]="markers" class="w-100 h-100"></app-maps>
                </div>
            </div>
        </div>

        <!-- Colonne de droite : Listing des localisations -->
        <div class="col-12 col-md-6 col-lg-6 mb-3">
            <div class="card h-100">
                <div class="d-flex justify-content-end py-2 px-2">
                    <a href="javascript:void(0);" class="btn btn-success m-2 mb-2 " (click)="openModal()">
                        <i class="mdi mdi-plus me-2"></i> Ajouter une localisation
                    </a>
                </div>

                <div class="table-responsive mt-3 px-2">
                    <table class="table table-centered datatable dt-responsive nowrap"
                        style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light rwd-table">
                            <tr>
                                <th>Localité</th>
                                <th>Commune</th>
                                <th>Arrondissement</th>
                                <th>Longitude</th>
                                <th>Latitude</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngIf="isLoading">
                                <td colspan="6" >
                                    <div class="text-center">
                                        <h4 id="graycolor">Chargement en cours</h4>
                                        <div class="loader4"></div>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="localisationItems.length === 0 && !isLoading">
                                <td colspan="6" >
                                    <div class="text-center">
                                        <h4 id="graycolor">Aucune donnée trouvée</h4>
                                    </div>
                                </td>
                            </tr>

                            <ng-container *ngFor="let item of localisationItems; let index = index">
                                <tr *ngIf="!isLoading">  
                                    <td>{{item?.nom_localite }}</td>
                                    <td>{{item?.arrondissement.commune.libelle }}</td>
                                    <td>{{item?.arrondissement.libelle }}</td>
                                    <td>{{item?.longitude }}</td>
                                    <td>{{item?.latitude }}</td>
                                    <td class="d-flex">
                                        <a (click)="exportToDocument(item)" style="cursor: pointer;" class="me-3 text-bg-light" title="Imprimer">
                                            <i class="mdi mdi-arrow-down-bold-box font-size-18"></i>
                                        </a>
                                        <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" title="Modifier">
                                            <i class="mdi mdi-pencil font-size-18"></i>
                                        </a>
                                        <a (click)="confirmDeletion(item.reference)" href="javascript:void(0);" class="text-danger" title="Supprimer">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #modalContent let-modal>
    <div class="container col-md-12">
        <div class="text-end py-2">
            <button (click)="modal.dismiss('Cross click')" type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="">
            <h4 class="d-flex justify-content-center"> {{ isEditMode ? 'Modifier la Localisation' : "Ajouter une Localisation" }}
            </h4>
            <form id="" class="ms-2 me-2"><br>
                <!-- zone departement communes -->
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Département</label>
                            <select name="departement" id="departement" class="form-select"
                                [(ngModel)]="selectedDepartementId" (change)="onDepartementChange($event.target.value)">
                                <option value="">Sélectionner un département</option>
                                <option *ngFor="let item of departement" [value]="item.id">{{ item.libelle }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="email-label" for="email">Commune</label>
                            <select name="commune" id="commune" class="form-select" [(ngModel)]="selectedCommuneId"
                                (change)="oncommuneChange($event.target.value)">
                                <option value="">Sélectionner une commune</option>
                                <option *ngFor="let item of commune" [value]="item.id">{{ item.libelle }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="email-label" for="email">Arrondissement </label>
                            <select name="programme_id" id="programme_id" class="form-select"
                                [(ngModel)]="localisation.arrondissement_id">
                                <option> Sélectionner un arrondissement</option>
                                <option *ngFor="let item of arrondissement" [value]="item.id"> {{item.libelle}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            
                <!-- zone localite longitude latitude -->
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Nom localité</label>
                            <input type="text" [(ngModel)]="localisation.nom_localite" name="nom_localite" id="name"
                              placeholder="Nom de la localité"  class="form-control" required  maxlength="100">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Longitude</label>
                            <input type="number" [(ngModel)]="localisation.longitude" name="longitude" id="name"
                            placeholder="0"  class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="email-label" for="email">Latitude</label>
                            <input type="number" [(ngModel)]="localisation.latitude" name="latitude" id="email"
                            placeholder="0"   class="form-control" required>
                        </div>
                    </div>
                </div>

                <!-- zone donnees geo -->
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Donnée géologique</label>
                            <textarea  placeholder="Veuillez renseigner une donnée géologique"  type="text" [(ngModel)]="localisation.donnee_geologique" name="donnee_geologique"
                                id="name" class="form-control" required  ></textarea>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="email-label" for="email">Donnée géophysique</label>
                            <textarea  placeholder="Veuillez renseigner une donnée géophysique"  [(ngModel)]="localisation.donnee_geophysique" name="donnee_geophysique"
                                id="donnee_geophysique" class="form-control" required  ></textarea>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Donnée géochimique</label>
                            <textarea  placeholder="Veuillez renseigner une donnée géochimique"  [(ngModel)]="localisation.donnee_geochimique" name="donnee_geochimique"
                                id="name" class="form-control" required  ></textarea>
                        </div>
                    </div>
                </div>
            
             
            
       
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="name">Perspectives </label>
                            <textarea  [(ngModel)]="localisation.perspective" name="perspectives" id="perspectives"
                            placeholder="Veuillez renseigner des perspectives"  class="form-control" required ></textarea>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="email-label" for="email">Date recherche</label>
                            <input type="date" [(ngModel)]="localisation.date_recherche" name="date_recherche"
                                id="email" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label id="name-label" for="resume_recherche">Résumé de la recherche </label>
                            <textarea  [(ngModel)]="localisation.resume_recherche" name="resume_recherche" id="resume_recherche"
                            placeholder="Veuillez faire un résumé"  class="form-control" required ></textarea>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group col-md-12">
                            <label for="photo">Rapport de recherche</label>
                            <input type="file" (change)="onFileSelected($event)" name="photo" class="form-control" id="rapport_recherche" >
                        </div>
                    </div>
                </div>
                
                <div class="text-end mb-2">
                    <div class="col-md-12">
                        <button (click)="modal.dismiss('Cross click')" type="button" class="btn btn-info ms-1"> 
                            <i class="pi pi-times" style="color: white"></i> Annuler
                        </button> &nbsp;
                        <button (click)="saveLocalisation()" type="submit" id="submit"
                            class="btn btn-success btn-block">
                            <i class="pi pi-save" style="color: white"></i> Enregistrer
                        </button>
                    </div>
                </div>
            
            </form>
            
        </div>
    </div>
</ng-template>



<ngx-ui-loader></ngx-ui-loader>
<p-toast />
<p-confirmDialog />
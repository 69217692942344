<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>QUI SOMMES-NOUS?</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>


<section class="why-choose section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <!-- Start Choose Left -->
                <div class="choose-left"><br>
                    <h3>Qui sommes-nous ?</h3>
                    <p class="description-text">
                        Créé le 13 août 1977 par décret N°77-29 sous le nom d'Office Béninois des Mines (OBEMINES),
                        l'Office Béninois de Recherches Géologiques et Minières a connu de nombreuses transformations
                        au fil des ans.
                    </p>

                    <p class="description-text">
                        D'abord OBEMINES en 1977, il est devenu un office scientifique et technique autonome
                        par décret n°99-281 du 31 mai 1999 sous le nom d'Office Béninois de Recherches Géologiques
                        et Minières.
                    </p>

                    <div>
                        <!-- Simple CSS Buttons -->
                        <div class="justify-content-end">
                            <a (click)="goToMission()" href="javascript: void(0);" class="btn btn-primary"
                                rel="noopener">Mission</a>
                        </div>
                    </div>
                </div><br>
                <!-- End Choose Left -->
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-center mt-2">
                <iframe width="660" height="315" src="https://www.youtube.com/embed/-2FVYNxzkVI?si=DiNjlp0NCaGidfJM"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</section><br>
<br>
<div class="container-fluid">

    <h4 id="upper">Projets - Directions ({{filteredtems.length}}) </h4>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div>
                        <!--  -->
                        <a href="javascript:void(0);" class="btn btn-success mb-2 ajout" id="gras"
                            (click)="openModal()">
                            <i class="mdi mdi-plus me-2"></i> <strong class="fw-bold">Ajouter une Direction</strong>
                        </a>
                    </div>
                    <div class="row mb-md-2">
                        
                        <!-- Search -->
                        <div class="d-flex justify-content-end">
                            <!-- Filtre par Direction -->
                            <div class="me-3">
                                <div id="tickets-table_filter" class="dataTables_filter">
                                    <label class="d-inline-flex align-items-center">
                                        Direction:
                                        <select style="width: 200px;" name="directionFilter"
                                            class="form-select ms-2" [(ngModel)]="selectedDirectionReference" (change)="filterProjetDirection()">
                                            <option value="">Sélectionner une direction</option>
                                            <option *ngFor="let item of directions" [value]="item.reference">{{ item?.libelle}}</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                    
                            <!-- Filtre par Projet -->
                            <div>
                                <div id="tickets-table_filter" class="dataTables_filter">
                                    <label class="d-inline-flex align-items-center">
                                        Projet:
                                        <select style="width: 200px;" name="projetFilter"
                                            class="form-select ms-2" [(ngModel)]="selectedProjetReference" (change)="filterProjetDirectionByProjet()">
                                            <option value="">Sélectionner un projet</option>
                                            <option *ngFor="let item of projetToUser" [value]="item.reference">{{item.sigle}}</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- End search -->
                    </div>
                    <div class="table-responsive mt-3">
                        <table class="table table-centered datatable dt-responsive nowrap"
                            style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                            <thead class="thead-light rwd-table">
                                <tr>
                                   
                                    <th>Direction</th>
                                    <th>Sigle Direction</th>
                                    <th>Projet</th>
                                    <th>Responsable Projet</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let item of paginatedProjets">
                                  
                                    <td>{{item.direction?.libelle}}</td>
                                    <td>{{item.direction?.sigle}}</td>
                                    <td>{{item.projet?.sigle}}</td>
                                   <td>{{item.projet?.responsable_projet}}</td>
                                    <td>
                                        <a (click)="confirmDeletion(item)" href="javascript:void(0);"
                                            class="text-danger" v-b-tooltip.hover title="Retirer">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                        <div class="container">
                            <div class="row">
                                <div class="text-center">
                                    <h5 id="graycolor">Chargement en cours...</h5>
                                    <div class="loader4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="paginatedProjets.length === 0 && isLoading === false"
                        class="d-flex justify-content-center">
                        <div class="container">
                            <div class="row">
                                <div class="text-center">
                                    <h5 id="graycolor">Aucune donnée trouvée</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                    <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets"
                        [rows]="rowsProjets" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                    </p-paginator>
                </div>
            </div>

        </div>

    </div>

    <ng-template #modalContent let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Ajouter une Direction à un projet</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mb-md-2">
                            <!-- <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="tickets-table_length"><label
                                        class="d-inline-flex align-items-center">Afficher
                                        <select name="tickets-table_length" [(ngModel)]="pageSize"
                                            aria-controls="tickets-table" name="pageSize"
                                            class="custom-select custom-select-sm form-control form-control-sm mx-2"
                                            (change)="changePageSize()">
                                            <option [ngValue]="10">10</option>
                                            <option [ngValue]="25">25</option>
                                            <option [ngValue]="50">50</option>
                                            <option [ngValue]="100">100</option>
                                        </select> lignes</label></div>
                            </div> -->
                            <!-- Search -->
                            <div class="col-sm-12 col-md-12 ">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Projet :
                                        <select [selectedIndex]="1" (change)="getDirectionCheckedByProjet()" style="width: 560px;"
                                            type="text" name="searchTerm" [(ngModel)]="itemProjet"
                                            class="form-select ms-2" aria-controls="tickets-table "
                                            aria-placeholder="Sélectionner un Projet" placeholder="Sélectionner un Projet">
                                            <option value="" disabled> Sélectionner un Projet</option>
                                            <option *ngFor="let item of projetToUser" [value]="item.reference">
                                              {{item.libelle}}  ({{item.sigle}})</option>
                                        </select>
                                    </label>
                                    <a (click)="saveTacheToProjet()" href="javascript:void(0);"
                                        class="btn btn-success mb-2 ms-2" id="gras">
                                        <i class="pi pi-save" style="font-size: 0.9rem"></i> Enregistrer
                                    </a>
                                </div>
                            </div>

                            <!-- End search -->
                        </div>
                        <div class="table-responsive mt-3">
                            <h6>Direction :</h6>
                            <table class="table table-centered datatable dt-responsive nowrap"
                                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                <thead class="thead-light  rwd-table">
                                    <tr>
                                        <th style="width: 26px;" class="sorting_disabled" rowspan="1" colspan="1">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="ordercheckAll"
                                                    (change)="toggleAll($event)">
                                                <label class="form-check-label mb-0" for="ordercheckAll">&nbsp;</label>
                                            </div>
                                        </th>
                                        <th>Libellé</th>
                                        <th>Sigle</th>

                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of items; let i = index">
                                        <td class="dtr-control">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="ordercheck{{i}}"
                                                    [(ngModel)]="item.isChecked" (change)="toggleItem(item)">
                                                <label class="form-check-label mb-0"
                                                    for="ordercheck{{i}}">&nbsp;</label>
                                            </div>
                                        </td>

                                        <td>{{item?.libelle}}</td>
                                        <td>{{item?.sigle}}</td>

                                        <!-- <td>
                          <a (click)="DetailModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                            title="Indicateurs">
                            <i class="mdi mdi-link font-size-18"></i>
                          </a>
                          <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                            title="Modifier">
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>
                          <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            title="Retirer">
                            <i class="mdi mdi-trash-can font-size-18"></i>
                          </a>
                        </td> -->
                                    </tr>

                                    <tr *ngIf="items.length === 0 && isLoading === false">
                                        <td colspan="8">
                                            <div class="row">
                                                <div class="text-center">
                                                    <h5 id="graycolor">Aucune donnée trouvée</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                            <div class="container">
                                <div class="row">
                                    <div class="text-center">
                                        <h5 id="graycolor">Chargement en cours</h5>
                                        <div class="loader4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets"
                            [rows]="rowsProjets" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                        </p-paginator> -->
                    </div>
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
        </div>
    </ng-template>
</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>
    <!-- Navbar & Hero Start -->
    <!-- Navbar & Hero Start -->
    <div class="container-fluid position-relative p-0">
        <nav style="background-color: #364CA0;" class="navbar navbar-expand-lg navbar-light  px-4 px-lg-5 py-3 py-lg-0">
            <a routerLink="/suivi/public" href="javascript: void(0);"  class="navbar-brand p-0">
                <!-- <img src="../../../assets/img/logo-white.png" alt="Logo"> &nbsp; &nbsp; -->
                <img  style="height: 5660px;" src="../../../assets/images/logo-obgrm-2.png"  alt="">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a routerLink="/" href="javascript: void(0);" class="nav-item nav-link ">Accueil</a>
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Présentation</a>
                        <div class="dropdown-menu m-0">
                            <a routerLink="/apropos" href="javascript: void(0);" class="dropdown-item">Qui Sommes Nous?</a>
                            <a routerLink="/mission" href="javascript: void(0);"  href="feature.html" class="dropdown-item">Mission</a>
                            <a routerLink="/metiers" href="javascript: void(0);"  class="dropdown-item">Métiers</a>
                            <a routerLink="/realisations" href="javascript: void(0);"  class="dropdown-item">Réalisations</a>
                            <a routerLink="/article" href="javascript: void(0);"  class="dropdown-item">Gestion des Substances Explosives</a>
                        </div>
                    </div>
                    <a routerLink="/recherches"  href="javascript: void(0);"class="nav-item nav-link">Recherche</a>
                    <a routerLink="/valorisation" href="javascript: void(0);" class="nav-item nav-link">Valorisation</a>

                    <a routerLink="/contact" href="javascript:void(0);" class="nav-item nav-link">Contact</a>
                </div>
                <a routerLink="/login" href="javascript: void(0);" style="background-color: white; color: black;"
                    class="btn rounded-pill py-2 px-4 flex-wrap flex-sm-shrink-0">Connexion</a>
            </div>
        </nav>



        <!-- Carousel End -->
    </div>
    <!-- Navbar & Hero End -->

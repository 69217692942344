<div class="container-fluid">

  <h4 id="upper">Cibles des indicateurs</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">

            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input [(ngModel)]="searchTerm" type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                    aria-controls="tickets-table" placeholder="" (keyup)="applyFilter($event.target.value)" (input)="applyFilter($event.target.value)"></label></div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th id="upper">Année</th>
                  <th>Valeur</th>
                  <th>Indicateur</th>
                  <th>Projet</th>
                  <th style="width: 120px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedItems; let index = index">

                  <td>{{item?.annee}}</td>
                  <td>{{item?.valeur_cible}}</td>
                  <td>{{item?.indicateur?.libelle}}</td>
                  <td>{{item?.indicateur.projet?.libelle}}</td>

                  <td>
                    <a (click)="openModalUpdateCible(item)" style="cursor: pointer;" class="me-3 text-primary"
                      v-b-tooltip.hover title="Edit">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a (click)="confirmationSuppression(item)" href="javascript:void(0);" class="text-danger"
                      v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center" *ngIf="isLoading === true">
            <div class="container">
                <div class="row">
                    <div class="text-center">
                        <h5 id="graycolor">Chargement en cours...</h5>
                        <div class="loader4"></div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="paginatedItems.length === 0 && isLoading === false"
            class="d-flex justify-content-center">
            <div class="container">
                <div class="row">
                    <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                </div>
            </div>

        </div>
        <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets"
            [rows]="rowsProjets" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
      </p-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cibleModal let-modal>
  <div>
    <div class="">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h5 id="upper" class="modal-title">Modification valeur cible</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <!-- Indicateur Information -->
              <div class="col-md-8">
                <div class="card mb-4">
                  <div class="container mb-3 text-left">
                    <h6 id="upper" class="mb-4 text-center">Informations de l'indicateur</h6>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Année :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.annee}}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Valeur cible
                          :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.valeur_cible}}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Ecart :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.ecart}}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Indicateur :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.indicateur?.libelle}}</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Formulaire de Planification -->
              <div class="col-md-4">
                <h5 class="mb-4 text-center">Réaliser un Indicateur</h5>
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label for="annee">Année</label>
                        <select name="annee" [(ngModel)]="cible.annee" id="annee" class="form-select">
                          <option *ngFor="let annee of annees" [value]="annee">{{annee}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label for="valeur_cible">Valeur cible</label>
                        <input type="number" class="form-control" id="valeur_cible" [(ngModel)]="cible.valeur_cible"
                          name="valeur_cible">
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer ">
                    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')"><i
                        class="pi pi-times" style="color: white"></i> Annuler</button>
                    <button (click)="updateValeurCible()" type="button" class="btn btn-primary"> <i class="pi pi-save"
                        style="color: white"></i> Modifier</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

        <!-- Modal Footer -->

      </div>
    </div>
  </div>
</ng-template>
<ngx-ui-loader>

</ngx-ui-loader>

<p-confirmDialog />
<p-toast />
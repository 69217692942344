import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-projetdirection',
  templateUrl: './projetdirection.component.html',
  styleUrl: './projetdirection.component.scss'
})
export class ProjetdirectionComponent {
  isLoading: boolean = false;
  searchTerm: string = '';
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  filteredtems: any = [];
  paginatedProjets = [];
  items = [];
  pageSize: number = 10;
  referenceUser: any;
  projetToUser: any = [];
  itemProjet: any;
  directionItems: any;
  projetItems: any[] = [];
  userInfo :any
  showFiltre :boolean =false;
  directions:any =[];

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  selectedReferences: Set<string> = new Set();

  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private operationService: OperationService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
  ) {


  }

  ngOnInit(): void {
    this.getDirectionToProjet();

    this.referenceUser = this.localstorage.getInfoUserToService();

    ////console.log("la reference de l'utilisteur", this.referenceUser);
    this.getProjetByUser();
    this.getDirectionToUser();
    this.getDirection();


  }


  getDirectionToProjet() {
    this.isLoading = true;
    this.paramservice.getDirection().subscribe(
      (response: any) => {
        this.isLoading = false;
        //console.log('Données reçues:', response);
        if (response && response.data) {
          this.directionItems = response.data;
          this.totalRecords = this.directionItems.length;
          this.filteredtems = response.data;
          this.updatePaginated()
          //console.log('Direction Items:', this.directionItems);  // Vérifiez les données ici
        } else {
          this.directionItems = [];
        }
      },
      (error) => {
        this.isLoading = false;
        //console.log('Erreur lors de la récupération des données:', error);
      }
    );
  }

  getDirection(){

    this.paramservice.fetchDataDirection().subscribe(

      (response:any)=>{

        //console.log("la reponse du webservice",response);

        this.directions = response.data;
        
      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }
    )
  }

 

  getProjetByUser() {

    this.paramservice.getStatsProjet().subscribe(

      (response: any) => {

        //console.log("la reponse du webservice", response);
        this.projetToUser = response.data;
      },
      (error) => {

        //console.log("une erreur est ", error);

      }
    )
  }
  onPageChangeProjets(event) {
    this.firstProjets =  event.first; // Mise à jour de la page actuelle
    this.rowsProjets =  event.rows; // Mise à jour de la page actuelle
    this.updatePaginated();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginated() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.directionItems.slice(start, end);
  }
  selectedDirectionReference: string = '';
  selectedProjetReference: string = '';

  filterProjetDirection(): void {

    //console.log("selectedDirectionReference", this.selectedDirectionReference);
    //console.log("selectedProjetReference", this.selectedProjetReference);
  
    const filteredItems = this.directionItems.filter(item => {
      const matchesDirection = this.selectedDirectionReference
        ? item.direction?.reference === this.selectedDirectionReference
        : true;
      
      //console.log("matchesDirection", matchesDirection);
  
      // Filtrer les éléments qui correspondent soit à la direction, soit au projet, soit aux deux
      return matchesDirection;
    });
  
    //console.log("filteredItems", filteredItems);
  
    // Met à jour la liste paginée après filtrage
    this.totalRecords = filteredItems.length;
    this.paginatedProjets = filteredItems.slice(this.firstProjets, this.firstProjets + this.rowsProjets);
    this.filteredtems = filteredItems;
  }


  filterProjetDirectionByProjet(): void {

    //console.log("selectedDirectionReference", this.selectedDirectionReference);
    //console.log("selectedProjetReference", this.selectedProjetReference);
  
    const filteredItems = this.directionItems.filter(item => {
     
      const matchesProjet = this.selectedProjetReference
        ? item.projet?.reference === this.selectedProjetReference
        : true;
  
      //console.log("matchesProjet", matchesProjet);
  
      // Filtrer les éléments qui correspondent soit à la direction, soit au projet, soit aux deux
      return matchesProjet;
    });
  
    //console.log("filteredItems", filteredItems);
  
    // Met à jour la liste paginée après filtrage
    this.totalRecords = filteredItems.length;
    this.paginatedProjets = filteredItems.slice(this.firstProjets, this.firstProjets + this.rowsProjets);
    this.filteredtems = filteredItems;

  }



  getDirectionToUser() {

    this.paramservice.fetchDataDirection().subscribe(

      (response: any) => {

        this.items = response.data;
        //console.log("la reponse du webservice", this.projetToUser);

      },
      (error) => {

        //console.log("une erreur est ", error);

      }
    )
  }


  
  toggleItem(item: any) {
    if (item.isChecked) {
      this.selectedReferences.add(item.reference);

      //console.log("this.selectedReferences", this.selectedReferences);

    } else {
      this.selectedReferences.delete(item.reference);
      //console.log("this.selectedReferences", this.selectedReferences);

    }
  }

  toggleAll(event: any) {
    //console.log("isckeked",);
    
    const isChecked = event.target.checked;
    this.items.forEach(item => {
      item.isChecked = isChecked;
      if (isChecked) {
        this.selectedReferences.add(item.reference);
        //console.log("this.selectedReferences",this.selectedReferences);

      } else {
        this.selectedReferences.delete(item.reference);
      }
    });
  }


  getDirectionCheckedByProjet(){

    //console.log("appeler");
    
     const requestData = {
        "projet_id" : this.itemProjet
     }

     this.operationService.getProjetToDirection(requestData).subscribe(

      (response:any)=>{

        //console.log("la reponse du webservice",response);
        
        const directionsAssociees = response.data.map((assoc: any) => assoc.direction.reference);

            // Parcours de la liste des items et cochez ceux qui correspondent aux directions associées
            this.items.forEach(item => {
                item.isChecked = directionsAssociees.includes(item.reference);
            });

      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }
     )

  }

  saveTacheToProjet() {

    //console.log("this.itemProjet", this.itemProjet);

    if (!this.itemProjet) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez sélectionnez un projet" });
      return;
    }
    if (!this.selectedReferences) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez cocher au moins une direction" });
      return
    }
    //console.log("Saving references:", Array.from(this.selectedReferences));

    this.ngxService.start();
    const requestdata = {
      "projet_id": this.itemProjet,
      "direction_ids": Array.from(this.selectedReferences) // convertir en un tableaux les reference cocher
    };

    this.paramservice.saveTacheToProjet(requestdata).subscribe(
      (response: any) => {
        this.ngxService.stop();
        //console.log("la reponse du webservice", response);
        this.getDirectionToProjet();

        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        this.modalService.dismissAll();
      },
      (error: any) => {
        this.ngxService.stop();
        //console.log("une erreur est survenue", error);
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "une erreur est survenu" });

      }
    );
  }

  name: any
  openModal() {
    this.getDirectionToUser();
    this.modalService.open(this.modalContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }


  
  //methode de suppression
  confirmDeletion(project: any) {
    //console.log("project",project);
    
    this.confirmationService.confirm({
        message: 'Êtes-vous sûr de vouloir supprimer ce projet :' + project?.projet?.libelle + '?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', 
        rejectLabel: 'Non', 
        accept: () => {
          this.ngxService.start();
            this.paramservice.deleteProjetToDirection(project?.id).subscribe(
              (response:any)=>{
                this.ngxService.stop();
                //console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message });
                this.getDirectionToProjet();
              },
              (error)=>{
                this.ngxService.stop();
                //console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });  
              },
            )
          },
        reject: () => {
            //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
        }
    });
  }

 

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';
import { ChartType, Stat, Chat, Transaction } from './dashboard.model';
import { revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, transactions, spark3Chart, spark2Chart, spark1Chart } from './data';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  statData: Stat[];
  showUser:any;
  userInfo: any;
  annee: any;
  items: any =[]=[];
  projetData: any[] = [];
  statsEnretard: any;
  @ViewChild('detailModal') modalContent: TemplateRef<any>;
  paginatedItems = [];
  first: number = 0; // La page actuelle (commence à 0)
  rows: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localStorage : LocalStorageService,
    private modalService: NgbModal,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
  ) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  revenueChart: ChartType;
  revenueChartotal: ChartType;
  revenuhistogrammeData :ChartType
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;
  spark1: any;
  spark2: any;
  spark3: any;

    date_debut:any
    date_fin:any
  
  // Form submit
  chatSubmit: boolean;
  formData: UntypedFormGroup;



  ngOnInit(): void {
   this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
   this.userInfo = this.localstorage.getInfoUserToService();
   this.showUser = this.userInfo?.data?.groupe;
   this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

   this.getHistogrammeData();
   this.getStatsEnRetard();
  }

   //recuperation des statistiques
   getHistogrammeData() {
    //check
    const currentYear = new Date().getFullYear();
    let dateDebut = `${currentYear}-01-01`;
    let dateFin = new Date().toISOString().split('T')[0];
    
    this.date_debut = dateDebut;
    this.date_fin = dateFin;
    //this.isLoading = true;
    this.ngxService.start();
    this.paramservice.getStatistiqueTepProjet(this.annee, dateDebut, dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.ngxService.stop();
        this.projetData = response.data;

        this.transformToHistogramme(this.projetData);

        //this.totalRecords = this.projetData.length;
      },
      (error) => {
        this.ngxService.stop();
        this.messageService.add({ severity: 'error', summary: 'TEP', detail: error, life: 3000 });
        //this.isLoading = false;
      }
    );
  }//end getStatistique


  //stats
  getStatsEnRetard(){
    this.paramservice.getStatsEnRetard().subscribe(
      (response:any)=>{
        this.statsEnretard = response.data;
       // console.log("la reponse du webservcie ",this.statsEnretard);
      },
      (error:any)=>{
       // console.log("une erreur est survenu",error);
      }
    )
  }//end getStatsEnRetard

  onPageChange(event) {
    this.first =  event.first; // Mise à jour de la page actuelle
    this.rows =  event.rows ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.first;
    const end = start + this.rows;
    this.paginatedItems = this.items.slice(start, end);
  }



  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: 'right',
        name: 'Ricky Clark',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes()
      });
      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });
    }
    this.chatSubmit = true;
  }

  //get histogramme data
  // transformToHistogramme(data: any) {

  //   this.revenueChart = {
  //     series: [],
  //     chart: {
  //       height: 400, // Set default values
  //       type: 'bar',
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     stroke: {
  //       width: 2,
  //       curve: 'smooth'
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '50%' 
  //       }
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       style: {
  //         colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'] 
  //       }
  //     },
  //     legend: {
  //       show: true
  //     },
  //     colors: ['#000', '#fff', ], 
  //     labels: [] 
  //   };

  //   this.revenueChartotal = {
  //     series: [],
  //     chart: {
  //       height: 400, // Set default values
  //       type: 'bar',
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     stroke: {
  //       width: 2,
  //       curve: 'smooth'
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '50%' 
  //       }
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       style: {
  //         colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'] 
  //       }
  //     },
  //     legend: {
  //       show: true
  //     },
  //     colors: ['#337aff', '#131415', ], 
  //     labels: [] 
  //   };
  //   //labels for projects
  //     let tabLabels = data.map(item => '' + item.sigle);
      
  //     this.revenueChart.labels = tabLabels;
  //     this.revenueChartotal.labels = tabLabels;
  //    // console.log("tabLabels",this.revenueChartotal.labels);

  //     //tep prevu
  //     let tabTepPrevu = data.map(item => item.tep_prevu);
  //     //console.log("tabTepPrevu",tabTepPrevu);
      
  //     this.revenueChart.series.push({
  //       name: "TEP Prévu", 
  //       type: 'column', 
  //       data: tabTepPrevu 
  //     });

  //     //tep realisé
  //     let tabTepRealise = data.map(item => item.tep_realise);
  //     //console.log("tabTepRealise",tabTepRealise);
      
  //     this.revenueChart.series.push({
  //       name: "TEP Réalisé", 
  //       type: 'column', 
  //       data: tabTepRealise 
  //     });

  //     //total engage
  //     let totalEngage = data.map(item => item.total_engage);
  //     //console.log("totalEngage",totalEngage);
  //     // totalEngage =[10,50,100]
  //     this.revenueChartotal.series.push({
  //       name: "Total engagé", 
  //       type: 'column', 
  //       data: totalEngage 
  //     });

  //       //total ordonnance

  //       let totalOrdonnance = data.map(item => item.total_ordonnance);
  //       //console.log("totalOrdonnance",totalOrdonnance);
  //     //  totalOrdonnance =[10,50,100]

  //       this.revenueChartotal.series.push({
  //         name: "Total ordonnancé", 
  //         type: 'column', 
  //         data: totalOrdonnance 
  //       });

  //   //console.log(this.revenueChart);

  // }//end transformToHistogramme

  transformToHistogramme(data: any) {

    this.revenueChart = {
      series: [],
      chart: {
        height: 400,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%' 
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'] 
        }
      },
      legend: {
        show: true
      },
      // Mise à jour des couleurs des histogrammes
      colors: ['#66BB6A', '#EF5350', '#FF7043'], // Vert pour "Prévu", Rouge pour "Réalisé", Orange pour "Ecart"
      labels: [] 
    };
  
    this.revenueChartotal = {
      series: [],
      chart: {
        height: 400,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%' 
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'] 
        }
      },
      legend: {
        show: true
      },
      // Couleurs pour ce graphique aussi
      colors: ['#66BB6A', '#EF5350', '#FF7043'], // Même logique de couleurs ici si nécessaire
      labels: [] 
    };
  
    // Labels des projets
    let tabLabels = data.map(item => '' + item.sigle);
    this.revenueChart.labels = tabLabels;
    this.revenueChartotal.labels = tabLabels;
  
    // TEP Prévu (Vert)
    let tabTepPrevu = data.map(item => item.tep_prevu);
    this.revenueChart.series.push({
      name: "TEP Prévu", 
      type: 'column', 
      data: tabTepPrevu 
    });
  
    // TEP Réalisé (Rouge)
    let tabTepRealise = data.map(item => item.tep_realise);
    this.revenueChart.series.push({
      name: "TEP Réalisé", 
      type: 'column', 
      data: tabTepRealise 
    });
  
    // Total Engagé (Orange)
    let totalEngage = data.map(item => item.total_engage);
    this.revenueChartotal.series.push({
      name: "Total engagé", 
      type: 'column', 
      data: totalEngage 
    });
  
    // Total Ordonnancé (Orange)
    let totalOrdonnance = data.map(item => item.total_ordonnance);
    this.revenueChartotal.series.push({
      name: "Total ordonnancé", 
      type: 'column', 
      data: totalOrdonnance 
    });
  
  }
  
  openModalUpdateCible(item:any) {

    this.totalRecords = item.length;
    this.items = item;

    //console.log( " this.items ",this.items );
    
    this.updatePaginatedProjets();
      this.modalService.open(this.modalContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicDashboardComponent } from './public-dashboard/public-dashboard.component';
import { ContactComponent } from './page/contact/contact.component';
import { RechercheComponent } from './page/recherche/recherche.component';
import { ValorisationComponent } from './page/valorisation/valorisation.component';
import { AproposComponent } from './page/presentation/apropos/apropos.component';
import { MissionComponent } from './page/presentation/mission/mission.component';
import { MetierComponent } from './page/presentation/metier/metier.component';
import { RealisationComponent } from './page/presentation/realisation/realisation.component';
import { ResourceDetailComponent } from './page/resource-detail/resource-detail.component';
import { CategorieRessourceComponent } from './page/categorie-ressource/categorie-ressource.component';
import { CentreDetailComponent } from './page/centre-detail/centre-detail.component';
import { ArticleComponent } from './page/presentation/article/article.component';

const routes: Routes = [

  { path: 'contact',  component: ContactComponent },
  { path: 'recherches',  component: RechercheComponent },
  { path: 'valorisation',  component: ValorisationComponent },
  { path: 'apropos',  component: AproposComponent },


  { path: 'mission',  component: MissionComponent },
  { path: 'metiers',  component: MetierComponent },
  { path: 'realisations',  component: RealisationComponent },
  { path: 'article',  component: ArticleComponent },

  
  { path: 'resource/:reference', component: ResourceDetailComponent },
  { path: 'resource/categorie/:reference', component: CategorieRessourceComponent },
  { path: 'centre/:reference', component: CentreDetailComponent },



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
 
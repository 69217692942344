<div class="container-fluid">

  <h4 id="upper">Point global OBRGM({{projetData.length}})</h4>
  <div class="d-flex justify-content-end">
    <!-- Ajout des champs de date -->
    <div class="me-3">
      <label class="d-inline-flex align-items-center">
        Début:
        <input disabled type="date" class="form-control ms-2" [(ngModel)]="dateDebut" name="dateDebut">
      </label>
    </div>

    <div class="me-3">
      <label class="d-inline-flex align-items-center">
        Fin:
        <input type="date" class="form-control ms-2" [(ngModel)]="dateFin" name="dateFin">
      </label>
    </div>
    <div class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <input (click)="getStatistique()" type="button" value="Rechercher" class="btn btn-primary">
      </div>
    </div>

  </div>
  <div class=" d-flex flex-column align-items-center mt-4">
    <div class="row mb-4 ">
      <div class="col-md-12 ">
        <div class="card">
          <div class="card-body">
            <h5 id="upper" class="card-title text-center">Montants par projet</h5>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%; border: 1px solid #dee2e6;">
         <thead class="thead-light rwd-table">
           <tr>
             <th style="background-color: #dbeafe; text-align: center;">Libellé</th>
             <th style="background-color: #dbeafe; text-align: center;">Montant financement état</th>
             <th style="background-color: #dbeafe; text-align: center;">Montant fonds propres</th>
             <th style="background-color: #dbeafe; text-align: center;">Montant PTF</th>
             <th style="background-color: #dbeafe; text-align: center;">Total</th>
             <th style="background-color: #dbeafe; text-align: center;">%</th>
             <th style="background-color: #dbeafe; text-align: center;">Rang</th>

           </tr>
         </thead>
         <tbody>
           <tr *ngFor="let projet of projetDataWithRank; let i = index">
             <td style="text-align: center;">{{ projet.nom_projet }}</td>
             <td style="text-align: center;">{{ projet.mt_financement_etat | formatMoney: null }}</td>
             <td style="text-align: center;">{{ projet.mt_financement_fond_propre | formatMoney: null }}</td>
             <td style="text-align: center;">{{ projet.mt_financement_ptf | formatMoney: null }}</td>
             <td style="text-align: center;">{{ projet.totalParLigne | formatMoney: null }}</td>
             <td style="text-align: center;">{{ projet.pourcentageParLigne }}%</td>
             <td style="text-align: center;">{{ projet.rang }}</td>

           </tr>
           <!-- Ligne TOTAL -->
           <tr style="background-color: #fff3cd; font-weight: bold;">
             <td style="text-align: center;">TOTAL</td>
             <td style="text-align: center;">{{ totalFinancementEtat | formatMoney: null }}</td>
             <td style="text-align: center;">{{ totalFondsPropres | formatMoney: null }}</td>
             <td style="text-align: center;">{{ totalMontantPTF | formatMoney: null }}</td>
             <td style="text-align: center;">{{ totalMontantAlloue | formatMoney: null }}</td>
             <td style="text-align: center;">-</td>
             <td style="text-align: center;">-</td>
           </tr>
           <!-- Ligne pourcentages -->
           <tr style="background-color: #e3fcec; font-weight: bold;">
             <td style="text-align: center;">Pourcentage(%)</td>
             <td style="text-align: center;">{{ pourcentageEtat.toFixed(2) }}%</td>
             <td style="text-align: center;">{{ pourcentageFondsPropres.toFixed(2) }}%</td>
             <td style="text-align: center;">{{ pourcentagePTF.toFixed(2) }}%</td>
             <td style="text-align: center;">-</td>
             <td style="text-align: center;">-</td>
             <td style="text-align: center;">-</td>
           </tr>
           <!-- Aucune donnée -->
           <tr *ngIf="projetDataWithRank.length === 0 && !isLoading">
             <td colspan="8">
               <div class="text-center">
                 <h5 id="graycolor">Aucune donnée trouvée</h5>
               </div>
             </td>
           </tr>
         </tbody>
       </table>
       


            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row ">
      <div class="col-md-12   ">
        <div class="card">
          <div class="card-body">
            <h5 id="upper" class="card-title text-center">Résumé par projet</h5>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%; border: 1px solid #dee2e6;">
                <thead class="thead-light rwd-table">
                  <tr>
                    <th style="background-color: #dbeafe; text-align: center;">Libelle</th>
                    <th style="background-color: #dbeafe; text-align: center; white-space: nowrap;">
                      Taux d'exécution physique prévu</th>
                    <th style="background-color: #dbeafe; text-align: center; white-space: nowrap;">
                      Taux d'exécution physique réalisé</th>
                    <th style="background-color: #dbeafe; text-align: center;">Écart</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let projet of projetData">
                    <td style="text-align: center;">{{ projet.nom_projet }}</td>
                    <td style="text-align: center;">{{ projet.tep_prevu }}</td>
                    <td style="text-align: center;">{{ projet.tep_realise }}</td>
                    <td style="text-align: center;">{{ projet.ecart }}</td>
                  </tr>
                  <tr style="background-color: #fff3cd; font-weight: bold;">
                    <td style="text-align: center;">TOTAL</td>
                    <td style="text-align: center;">{{ totalTepPrevu }}</td>
                    <td style="text-align: center;">{{ totalTepRealise }}</td>
                    <td style="text-align: center;">{{ totalEcart }}</td>
                  </tr>
                  <tr *ngIf="projetData.length === 0 && !isLoading">
                    <td colspan="4">
                      <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Section de chargement -->
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <div class="container">
      <div class="row">
        <div class="text-center">
          <h5 id="graycolor">Chargement en cours</h5>
          <div class="loader4"></div>
        </div>
      </div>
    </div>
  </div>

</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>
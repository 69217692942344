import { Component } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { reference } from '@popperjs/core';
import { OperationService } from './../../../../../shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';

@Component({
  selector: 'app-indicateur-cle',
  templateUrl: './indicateur-cle.component.html',
  styleUrl: './indicateur-cle.component.scss'
})
export class IndicateurCleComponent {
  tauxEngagement: any ;
  tauxOrdonnancement: any;
  tauxTEP: any;
  date_debut: string;
  date_fin: string;
  user_ref: any;
  data: any;
  options: any;
  isLoading: boolean = false; 
  constructor(
    private localStorage: LocalStorageService,
    private ParameterService: ParameterService
  ) {
    const currentYear = new Date().getFullYear();
    let dateDebut = `${currentYear}-01-01`;
    let dateFin = new Date().toISOString().split('T')[0];
    
    this.date_debut = dateDebut;
    this.date_fin = dateFin;
  }

  ngOnInit() {
    
    this.user_ref = this.localStorage.getInfoUserToService()?.data?.reference;
    this.getIndicateurCalcule();
   //this.generateBarChart()
  }
  getIndicateurCalcule() {
    const requesData = {
      "user_ref": this.user_ref,
      "date_fin": this.date_fin,
      "date_debut": this.date_debut,
    };
    this.isLoading = false
    console.log("requesData", requesData);
  
    this.ParameterService.getIndicateurCle(requesData).subscribe(
      (response: any) => {
        console.log("Réponse du webservice", response);
        this.isLoading =true;
  
        if (response.status !== 'success') {
          console.error("Statut du web service : ", response.status);
          return;
        }
  
        if (!Array.isArray(response.data)) {
          console.error("La propriété 'data' n'est pas un tableau", response.data);
          return;
        }
  
        try {
          const tauxEngagement = [];
          const tauxOrdonnancement = [];
          const tauxTEP = [];
  
          response.data.forEach((moisData: any[]) => {
            moisData.forEach((moisItem: any) => {
              tauxEngagement.push(moisItem.taux_engagement);
              tauxOrdonnancement.push(moisItem.taux_ordonnancement);
              tauxTEP.push(moisItem.tep.tep_prevu);
            });
          });
  
          this.tauxEngagement = tauxEngagement;
          this.tauxOrdonnancement = tauxOrdonnancement;
          this.tauxTEP = tauxTEP;
  
          this.generateBarChart();
        } catch (error) {
          console.error("Erreur lors de l'extraction des données", error);
        }
      },
      (error) => {
        console.error("Une erreur est survenue", error);
      }
    );
  }
  
  
  generateBarChart() {
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Jullet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    // Configuration des données pour l'histogramme
    this.data = {
      labels: months,
      datasets: [
        {
          label: 'Taux d\'Engagement',
          backgroundColor: '#42A5F5',
          data: this.tauxEngagement
        },
        {
          label: 'Taux d\'Ordonnancement',
          backgroundColor: '#FFA726',
          data: this.tauxOrdonnancement
        },
        {
          label: 'Taux d\'Exécution Physique',
          backgroundColor: '#66BB6A',
          data: this.tauxTEP
        }
      ]
    };
  


    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
          legend: {
             
          }
      },
      scales: {
          x: {
              ticks: {
                  font: {
                      weight: 500
                  }
              },
              grid: {
                  drawBorder: false
              },
              title: { display: true, text: 'Mois' }
          },
          y: {
              ticks: {
              },
              grid: {
                  drawBorder: false
              },
                title: { display: true, text: 'Taux (%)' },

          }

      }
  };
  }
  
}
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PtaSousActivite } from 'src/app/shared/models/ptasousactivite';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-decaissemnt',
  templateUrl: './decaissemnt.component.html',
  styleUrl: './decaissemnt.component.scss'
})

export class DecaissemntComponent {


  submitted: boolean;

  actionData: any = [];
  modalRef: NgbModalRef | null = null;
  item: any = {};
  itemToDelete!: any;
  @ViewChild('decaisemmentModal') modalContent: TemplateRef<any>;
  items: PtaSousActivite[] = [];
  itemDecaissement: any;
  projetId: any;
  isLoading: boolean = false;
  selectedDirection: any = {};


  paginatedItems: PtaSousActivite[] = [];

  firstEncaissement = 0;
  rowsEncaissemnt = 10;
  paginatedEncaissement: PtaSousActivite[] = [];
  totalRecords: number = 0; // Nombre total d'éléments
  itemsListCopy: any[] = [];

  firstDecaissement = 0;
  rowsDecaissement = 10;
  paginateDecaissment = [];
  totalRecords2: number = 0; // Nombre total d'éléments
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any;

  ExerciceItems: any = [];
  projetItems: any = [];
  annee: any;
  selectedProjet: any;
  directionId: any;
  sousActiviteItem: PtaSousActivite = new PtaSousActivite();


  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxservice: NgxUiLoaderService
  ) { }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();
    this.userInfo = this.localstorage.getInfoUserToService();


    this.getTacheDecaissemnt();
    this.getProjetsList();

    if (this.userInfo?.data?.groupe === "admin") {
      this.showFiltre = true;
      this.getDirection();
    }
    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;

      this.getProjetByDirection(this.directionId);
    }
    this.calculateReste();
  }//end 


  getProjetsList() {
    this.paramservice.getListUserProjects(this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;
        //console.log(this.projetItems);
      },
      (error) => {
      }
    )
  }//end getProjetsList

  getDirectionByProjet(reference: any) {
    this.paramservice.getDirectionsByProjet(reference).subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error: any) => {
      }
    )
  }//end getProjetByDirection

  processSelectedProjet() {
    //get sous activites associated
    this.getTacheDecaissemnt();

    //get direction associated
    this.getDirectionByProjet(this.selectedProjet.reference);
  }
  getEncaissement(item: any) {
    this.isLoading = true;
    this.ngxservice.start();
    this.paramservice.getEncaissement(item).subscribe(
      (response: any) => {
        this.items = response.data;
        this.totalRecords = this.items.length; // Met à jour le total des enregistrements
        this.ngxservice.stop();
        this.isLoading = false;
        this.updatePaginatedEncaissement();
      },
      (error) => {
        this.ngxservice.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "une erreur est survenu lors de la recuperation de la liste des ordonnancements", life: 3000 });
      }
    );
  }

  getDecaissement(item: any) {
    this.isLoading = true;

    this.paramservice.getDecaissement(item).subscribe(

      (response: any) => {
        this.ngxservice.stop()

        this.isLoading = false;
        this.itemDecaissement = response.data;
        this.totalRecords2 = this.itemDecaissement.length
        this.updatePaginatedDecaissement()
      },
      (error) => {
        this.ngxservice.stop()
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "une erreur est survenu lors de la recuperation de la liste des ordonnancements", life: 3000 });
      }
    );
  }



  openModal(sousActivite: PtaSousActivite) {
    console.log("sousActivite",sousActivite);
    
    if (sousActivite instanceof PtaSousActivite) {
      this.sousActiviteItem = sousActivite;
    } else {
      this.sousActiviteItem = Object.assign(new PtaSousActivite(), sousActivite);
    }

    this.modalService.open(this.modalContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }//end openModal

  //enregistrement saveEncaissement
  saveEncaissement() {

    if (!this.sousActiviteItem.canEncaissement()) {
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: this.sousActiviteItem.getErrorMessage() });
      return;
    }

    // if (this.sousActiviteItem.total_engage < (this.sousActiviteItem.total_ordonnance + this.sousActiviteItem.total_a_ordonnancer)) {
    //   this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: "Le montant ordonnancé ne peut pas dépasser le montant engagé." });
    //   return;
    // }
    else {
      this.ngxservice.start();
      this.paramservice.saveActiviteEncaissement(this.sousActiviteItem).subscribe(
        (response: any) => {

          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });
          this.ngxservice.stop();
          this.getTacheDecaissemnt()
          this.modalService.dismissAll();
        },
        (error) => {
          //  this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });
          this.ngxservice.stop();
        }
      )
    }
  }//end saveOrdonnancement


  //calcul reste
  calculateReste() {
    this.sousActiviteItem.reste =
      (Number(this.sousActiviteItem.total_engage) ?? 0) -
      (Number(this.sousActiviteItem.total_encaissement) ?? 0) -
      (Number(this.sousActiviteItem.total_a_encaisser) ?? 0);

  }//end calculateReste


  updatePaginatedEncaissement() {
    const start = this.firstEncaissement;
    const end = start + this.rowsEncaissemnt;
    this.paginatedEncaissement = this.items.slice(start, end);
  }

  // Méthode pour mettre à jour les données paginées des activités
  updatePaginatedDecaissement() {
    const start = this.firstDecaissement;
    const end = start + this.rowsDecaissement;
    this.paginateDecaissment = this.itemDecaissement.slice(start, end);
  }


  onPageEncaissement(event) {
    this.firstEncaissement = event.first
    this.rowsEncaissemnt = event.rows
    this.updatePaginatedEncaissement();
  }

  onPageChangeDecaissemnt(event) {
    this.firstDecaissement = event.first
    this.rowsDecaissement = event.rows
    this.updatePaginatedDecaissement();
  }


  getDirection() {
    this.paramservice.fetchDataDirection().subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error) => {
      }
    )
  }

  getProjetByDirection(reference: any) {
    this.paramservice.getProjetByDirection(reference).subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error: any) => {
      }
    )
  }

  getTacheDecaissemnt() {
    this.ngxservice.start()
    const request = {
      user_ref: this.userInfo?.data?.reference,
      projet_id: this.selectedProjet ? this.selectedProjet.reference : null,
      annee: this.annee,
    };

    this.getEncaissement(request);
    this.getDecaissement(request);
  }

  filterDataByDirections() {
    if (this.selectedDirection === null || this.selectedDirection === undefined || this.selectedDirection.direction_id === undefined) {
      this.items = this.itemsListCopy
    } else {
      this.items = this.itemsListCopy.filter(x => x.direction_id === this.selectedDirection.direction_id);
    }
  }//en filterDataByDirections


  //annuler cancelEncaissement
  cancelEncaissement(event: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Êtes-vous sûr de vouloir annuler cet encaissement :' + event.sous_activite + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxservice.start();
        this.paramservice.annulationEncaissement(event.reference).subscribe(
          (response: any) => {
            this.ngxservice.stop();
            this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'L\'engagement a été annulé' });
            this.getTacheDecaissemnt()
          },
          (error) => {
            this.ngxservice.stop();
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error, life: 3000 });
          },
        )
      },
    });
  }//end cancelEncaissement

}
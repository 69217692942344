import { ChangeDetectorRef, Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-taux-execution',
  templateUrl: './taux-execution.component.html',
  styleUrl: './taux-execution.component.scss'
})
export class TauxExecutionComponent {
  startDate: string = '';
  endDate: string = '';
  dateDebut: string;
  dateFin: string;

  constructor(
    private paramService: ParameterService,
    private ngxService: NgxUiLoaderService,
    private messageService: MessageService,
    private localstorage: LocalStorageService,


  ) {
    const currentYear = new Date().getFullYear();
    this.dateDebut = `${currentYear}-01-01`;
    this.dateFin = new Date().toISOString().split('T')[0];
    this.startDate =this.dateDebut
    this.endDate =this.dateFin
  }
  data: any;
  options: any;
  annee: any;
  userInfo: any

  legendItems: any[] = [];  
  ngOnInit(): void {
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();
    this.userInfo = this.localstorage.getInfoUserToService();

    this.generateInitialData();
    // Options du graphique
    this.options = {
      responsive: true,
      scales: {
        x: {
          title: {
            display: true,
            text: 'Mois'
          }
        },
        y: {
          title: {
            display: true,
            text: 'TEP (%)'
          }
        }
      },
      plugins: {
        legend: {
          position: 'top',
        },
      },
    };
    
  }

  generateInitialData() {
    if (!this.dateDebut || !this.dateFin) {
      console.error('Les dates de début et de fin sont requises');
      return;
    }
  
    const start = new Date(this.dateDebut);
    const end = new Date(this.dateFin);
  
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Les dates ne sont pas valides');
      return;
    }
    this.dateDebut= this.startDate;
    this.dateFin= this.endDate;
    const startMonthIndex = start.getMonth();
    const endMonthIndex = end.getMonth();
  
    if (startMonthIndex > endMonthIndex) {
      console.error('Plage de dates non valide: la date de début est après la date de fin');
      return;
    }
    this.ngxService.start();
    this.paramService.getTepByMonth(this.annee, this.dateDebut, this.dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        console.log("Réponse du webservice", response);
  
        // Vérification du succès de la réponse
        if (response.status === 'success' && response.data) {
          const statistics = response.data;
  
          // Traitement des données pour le graphique
          const labels = [];
          const tepRealise = [];
          const tepPrevu = [];
  
          statistics.forEach((monthlyData: any[]) => {
            monthlyData.forEach((item: any) => {
              labels.push(item.mois.nom_mois); // Ajouter le nom du mois
              tepRealise.push(item.tep.tep_realise); // Ajouter le TEP réalisé
              tepPrevu.push(item.tep.tep_prevu); // Ajouter le TEP prévisionnel
            });
          });
  
          // Couleurs pour chaque mois
          const monthColors = [
            '#FF6384',  // Janvier
            '#36A2EB',  // Février
            '#FFCE56',  // Mars
            '#4BC0C0',  // Avril
            '#9966FF',  // Mai
            '#FF9F40',  // Juin
            '#E91E63',  // Juillet
            '#9C27B0',  // Août
            '#00BCD4',  // Septembre
            '#8BC34A',  // Octobre
            '#FFC107',  // Novembre
            '#795548'   // Décembre
          ];
  
          // Mise à jour des items de légende avec les noms de mois et les couleurs
          this.legendItems = monthColors.map((color, index) => ({
            label: labels[index],
            color: color
          }));
  
          console.log("this.legendItems", this.legendItems);
  
          // Mise à jour des données du graphique
          this.data = {
            labels: labels.slice(startMonthIndex, endMonthIndex + 1),
            datasets: [
              {
                label: 'TEP Réalisé',
                data: tepRealise.slice(startMonthIndex, endMonthIndex + 1),
                fill: false,
                //borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.1,
                borderWidth: 2,
                pointBackgroundColor: monthColors.slice(startMonthIndex, endMonthIndex + 1),
                backgroundColor: monthColors.slice(startMonthIndex, endMonthIndex + 1),
                pointBorderColor: monthColors.slice(startMonthIndex, endMonthIndex + 1)
              },
              {
                label: 'TEP Prévisionnel',
                data: tepPrevu.slice(startMonthIndex, endMonthIndex + 1),
                fill: false,
                //borderColor: 'rgba(255, 99, 132, 1)',
                tension: 0.1,
                borderWidth: 2,
                pointBackgroundColor: monthColors.slice(startMonthIndex, endMonthIndex + 1),
                backgroundColor: monthColors.slice(startMonthIndex, endMonthIndex + 1),
                pointBorderColor: monthColors.slice(startMonthIndex, endMonthIndex + 1)
              }
            ]
          };
          
          this.ngxService.stop();

        } else {
          console.error('Données de réponse non valides');
          this.ngxService.stop();

        }
      },
      (error) => {
        console.log("Une erreur est survenue", error);
        this.ngxService.stop();

      }
    );
  }
  
  
}

<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2 style="">VALORISATION</h2>
                </div>
            </div>
        </div>
    </div>
</div><br>

<div class="container-fluid blog ">
    <div class="container py-4">
        <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <h1 style="" class=" mb-4">Sites d'essais pilotes </h1>
        </div>
        <div class="row g-4 justify-content-center">
            <div class="row justify-content-center" *ngIf="!centreItems || centreItems.length === 0">

                <div class="col-md-3">
                    <div class="card">
                        <p-skeleton width="20rem" height="25rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <p-skeleton width="20rem" height="25rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <p-skeleton width="20rem" height="25rem"></p-skeleton>
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp card " *ngFor="let item of centreItems"
            data-wow-delay="0.1s">
            <div class="blog-item rounded h-100 d-flex flex-column">
                <div class="blog-img">
                    <img [src]="item.image_url" class="img-fluid w-100" alt="Image"
                        style="height: 250px; object-fit: cover;">
                </div>
                <div class="blog-centent p-4 d-flex flex-column flex-grow-1">
                    <a href="#" class="h4" id="gras" style="font-size: 20px;">{{item.nom}}</a>
                    <p class="my-4 flex-grow-1">{{item.description}}</p>
                    <a  id="bordure" (click)="goToCentreDetail(item.reference )" href="javascript: void(0);"
                        class="btn btn-primary rounded-pill text-white py-2 px-4 mt-auto">En savoir +</a>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

<div class="container-fluid blog">
    <div class="container py-4">
        <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <h1 style="" class="mb-4">Référencement
                des sites d'essais pilotes</h1>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body p-0">
                        <app-maps [markers]="markers" class="w-100 h-100"></app-maps>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="justify-content-center">
  <div class="row">
    <div #mapContainer id="map" style="height: 600px;"></div>
  </div>
</div>

<ng-template #detailModal let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">


      <!-- Deuxième colonne avec ville et ressource -->
      <div class="col-md-12" *ngIf="details.type==='ressource'">
        <p><strong style="font-weight: bold; color: black;">Ressource:</strong> {{details.data.ressource}}</p>
        <p><strong style="font-weight: bold;color: black;">Type:</strong> {{details.data.type_ressource}}</p>
        <p><strong style="font-weight: bold;color: black;">Département:</strong> {{details.data.departement}}</p>
        <p><strong style="font-weight: bold;color: black;">Résumé de valorisation:</strong> {{details.data.resume}}</p>
        <p><strong style="font-weight: bold;color: black;">Document de valorisation:</strong> <a (click)="exportToDocument(details.data.reference)" href="javascript: void(0);">
            Voir le document <i class="pi pi-arrow-right

          " style="font-size: 0.7rem"></i>
          </a>
        </p>
      </div>

      <div class="col-md-12" *ngIf="details.type==='valorisation'">
        <p><strong style="font-weight: bold;color: black;"> <b>Nom</b>:</strong> {{details.data.nom}}</p>
        <p><strong style="font-weight: bold;color: black;">Description:</strong> {{details.data.description}}</p>
        <p><strong style="font-weight: bold;color: black;">Département:</strong> {{details.data?.items?.arrondissement?.commune?.departement?.libelle }}</p>
        <p><strong style="font-weight: bold;color: black;">Commune:</strong> {{details.data?.items?.arrondissement?.commune?.libelle}}</p>
        <p><strong style="font-weight: bold;color: black;">Arrondissement:</strong> {{details.data?.items?.arrondissement?.libelle}}</p>
      </div>

    </div>

    <div class="row mt-3" *ngIf="details.type==='ressource'">
      <!-- Description prend toute la largeur -->
      <div class="col-12">
        <p><strong>Description:</strong> {{details.data.description}}</p>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
  </div>
</ng-template>

<div class="container-fluid">

  <h4 id="upper">Sites d'essais pilotes</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!--  -->
            <a href="javascript:void(0);" class="btn btn-success mb-2" id="gras" (click)="openModal()">
              <i class="mdi mdi-plus me-2"></i> Ajouter un site d'essais pilotes
            </a>
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">

            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" (keyup)="fiterCentre()" [(ngModel)]="searchTerm"
                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"></label></div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Nom</th>
                  <th>Description</th>
                  <th>Arrondissement </th>
                  <th>Longitude</th>
                  <th>Latitude</th>
                  <th>Photo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedProjets; let index = index">

                  <td>{{item?.nom}}</td>
                  <!-- | limitTo: 50 -->
                  <td>{{item?.description }}</td>
                  <td>{{item?.arrondissement?.libelle}}</td>
                  <td>{{item?.longitude}}</td>
                  <td>{{item?.latitude}}</td>
                  <td>
                    <img [src]="item?.image_url" alt="Photo" style="width: 100px; height: 50px;">
                  </td>
                  <td>
                    <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                      title="Modifier">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a (click)="confirm1(item.reference)" href="javascript:void(0);" class="text-danger"
                      v-b-tooltip.hover title="Supprimer">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <div class="d-flex justify-content-center" *ngIf="actionData.length === 0">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h4 id="graycolor">Chargement en cours</h4>
                <div class="loader4"></div>
              </div>
            </div>
          </div>

        </div>
        <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
          [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
        </p-paginator>
      </div>
    </div>
  </div>
</div>



<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>
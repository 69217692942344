<div class="container-fluid">
  <h4 id="upper">Point financier par projet </h4>

  <!-- Filtres de date et projet -->
  <div class="row d-flex justify-content-end align-items-center">
    <div class="col-auto mb-3">
      <label class="d-flex align-items-center">
        Début:
        <input type="date" class="form-control ms-2 form-control-sm" [(ngModel)]="dateDebut" name="dateDebut">
      </label>
    </div>
    <div class="col-auto mb-3">
      <label class="d-flex align-items-center">
        Fin:
        <input type="date" class="form-control ms-2 form-control-sm" [(ngModel)]="dateFin" name="dateFin">
      </label>
    </div>
    <div class="col-auto mb-3">
      <label class="d-flex align-items-center">
        Direction:
        <select style="width: 200px;" type="text" name="searchTerm" class="form-select ms-2"
        aria-controls="tickets-table" [(ngModel)]="directionId" (change)="getProjetByDirection(directionId)">
        <option disabled value="Séléctionner une direction" ></option>
        <option *ngFor="let item of directionItems" [value]="item.reference"> {{ item?.libelle}}</option>
      </select>
      </label>
    </div>
    <div class="col-auto mb-3">
      <label class="d-flex align-items-center">
        Projet:
        <select class="form-select ms-2 form-select-sm" [(ngModel)]="selectedProjet">
          <option [value]="'DEFAULT'">Sélectionner un projet</option>
          <option *ngFor="let item of projetItems" [value]="item?.projet.reference">{{ item?.projet?.libelle }}</option>
        </select>
      </label>
    </div>
    <div class="col-auto d-flex justify-content-between align-items-center mb-3">
      <button (click)="getStatistique()" class="btn btn-primary">Rechercher</button>
<!-- 
      <div class="dropdown d-inline-block ms-2" ngbDropdown>
        <button style="font-size: 16px;" type="button" ngbDropdownToggle class="btn btn-primary btn-sm dropdown-toggle">
          Exporter <i class="pi pi-upload" style="font-size: 1rem"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('pdf')">Au format PDF</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('excel')">Au format Excel</a>
        </div>
      </div>
    </div> -->
  </div>


  <!-- Tableau des résultats financiers -->
  <div class="row card">
    <div class="d-flex flex-column flex-lg-row align-items-start">
      <!-- Tableau -->
      <div class="col-12 col-lg-7">
        <div class="card-body">
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Montant Prévu</th>
                  <th>Montant Engagé</th>
                  <th>Montant Ordonnancé</th>
                  <th>Taux d’Engagement</th>
                  <th>Taux d’Ordonnancement</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ projetData.montant_prevu | formatMoney:null }}</td>
                  <td>{{ projetData.montant_engage | formatMoney:null }}</td>
                  <td>{{ projetData.montant_ordonnance | formatMoney:null }}</td>
                  <td>{{ projetData.taux_engagement }}%</td>
                  <td>{{projetData.taux_ordonnancement }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isLoading === true">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h5 id="graycolor">Chargement en cours</h5>
                <!-- <div class="loader4"></div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
          [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
        </p-paginator> -->
      </div>

      <!-- Histogramme -->
      <div class="col-12 col-lg-5 ms-lg-4 mt-4 mt-lg-0">
        <p-chart type="bar" [data]="barChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>
  </div>

</div>

<p-toast></p-toast>
<ngx-ui-loader></ngx-ui-loader>
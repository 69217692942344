import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Ressource } from 'src/app/shared/models/ressource';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddCentreComponent } from '../add-centre/add-centre.component';
import { EditCentreComponent } from '../edit-centre/edit-centre.component';
import { AddResourceComponent } from '../../ressource/add-resource/add-resource.component';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { Constant } from 'src/app/shared/service/utils/contant';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-centre',
  templateUrl: './centre.component.html',
  styleUrl: './centre.component.scss'
})

export class CentreComponent  {

  breadCrumbItems: Array<{}>;

  submitted: boolean;

  actionData: any =[];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;

  itemToDelete!: Ressource; 
  searchTerm: string = '';

  pageSize: number = 10; 

  filteredtems: any = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets = [];

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private  FecthDataService :FecthDataService,
      private ngxService:NgxUiLoaderService

  ) {
    
    
    this.FecthDataService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création
      this.fetchData();
    });   }

  ngOnInit(): void {

   

    this.fetchData();


  }

   fetchData() {
    
    
    this.paramservice.fetchDatacentre().subscribe(

      (response:any)=>{

        this.actionData = response.data;
        this.filteredtems = this.actionData;  
        this.totalRecords = this.filteredtems.length;
        this.updatePaginatedProjets(); // Initial pagination
       // console.log("this.actionData",this.actionData);
        
      },
      (error)=>{
       // console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }
  onPageChangeProjets(event) {
    this.firstProjets =  event.first;
    this.rowsProjets =  event.rows ; 
    this.updatePaginatedProjets();
  }
  
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.filteredtems.slice(start, end);
  }

  openModal() {
    this.modalService.open(AddCentreComponent, {
       centered: true,
       backdrop: 'static', 
        size:"lg"

       });
  }

  EditModal(action:any) {
    this.localstorage.saveCentre(action)
      const modalRef = this.modalService.open(EditCentreComponent,{
        backdrop: 'static', 
        centered: true,
        size:"lg"

      }, );
  }

  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir supprimer ce centre?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {
            this.ngxService.start();
            this.paramservice.deleteCentre(event).subscribe(

              (response:any)=>{

               // console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'succes', summary: Constant.rechercheiTitle, detail: response.message });

                this.fetchData();
                this.ngxService.stop();

              },
              (error)=>{
                this.ngxService.stop();

               // console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });

                
              },
            )
          },
        // reject: () => {
        //     this.messageService.add({ severity: 'warn', summary: Constant.rechercheiTitle, detail: 'Vous avez rejeté', life: 3000 });
        // }
    });
}

fiterCentre() {
  const lowerSearchTerm = this.searchTerm.toLowerCase();
  this.filteredtems = this.actionData.filter((item: any) => {
      const matchesNom = item.nom && item.nom.toLowerCase().includes(lowerSearchTerm);
      const matchesDescription = item.description && item.description.toLowerCase().includes(lowerSearchTerm);
      const matchesLatitude = typeof item.latitude === 'number' && item.latitude.toString().toLowerCase().includes(lowerSearchTerm);
      const matchesLongitude = typeof item.longitude === 'number' && item.longitude.toString().toLowerCase().includes(lowerSearchTerm);
      const matchesArrondissement = item.arrondissement && item.arrondissement.libelle && item.arrondissement.libelle.toLowerCase().includes(lowerSearchTerm);
      const matchesCommune = item.arrondissement && item.arrondissement.commune && item.arrondissement.commune.libelle && item.arrondissement.commune.libelle.toLowerCase().includes(lowerSearchTerm);
      const matchesDepartement = item.arrondissement && item.arrondissement.commune && item.arrondissement.commune.departement && item.arrondissement.commune.departement.libelle && item.arrondissement.commune.departement.libelle.toLowerCase().includes(lowerSearchTerm);

      return matchesNom || matchesDescription || matchesLatitude || matchesLongitude || matchesArrondissement || matchesCommune || matchesDepartement;
  });
}


changePageSize() {
  this.filteredtems = this.filteredtems.slice(0, this.pageSize);
}

}